import { Alert, Button, Card, Label, Table, TextInput } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { formatToReal, isValidCNPJ, isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCNPJ, maskCPF } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryLoading from "../common/QueryLoading";
import QueryInput from "../inputs/QueryInput";

const CadinQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    if (valor.length <= 11) {
      valor = maskCPF(valor);
    } else {
      valor = maskCNPJ(valor);
    }

    setInfo(valor);
  };

  const fetchInfo = async () => {
    return await axios(`${QUERIES.GET_CADIN}/${info.replace(/\D/g, "")}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (unmaskedValue.length <= 11) {
      if (!isValidCPF(unmaskedValue)) {
        setError("CPF inválido!");
        isValid = false;
      }
    } else {
      if (!isValidCNPJ(unmaskedValue)) {
        setError("CNPJ inválido!");
        isValid = false;
      }
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setErrorData("");
        const { data: dados } = await fetchInfo();
        setData(dados.data);
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={["Dados Cadastrais", "Dívidas"]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF"
            />
            <div>
              {!error && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      {errorData && (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      )}
      {!errorData && (
        <div
          className={`${data || isLoading ? "block" : "hidden"}`}
          ref={componentRef}
        >
          {!isLoading ? (
            <Card class="card-pdf relative overflow-hidden p-5">
              <HeaderQuery title={query?.name} />
              <div>
                <TitleStripe>Quadro de avisos</TitleStripe>
                <Alert
                  withBorderAccent
                  color={`${data?.possuiDivida ? "failure" : "green"}`}
                  className={`mt-3 font-semibold`}
                >
                  {data?.status}
                </Alert>
                {data?.possuiDivida && data?.totalDivida && (
                  <div className="font-semibold text-red-700 text-lg mt-3">
                    Total da dívida: {formatToReal(data?.totalDivida)}
                  </div>
                )}
                {data?.possuiDivida && data?.totalTributario && (
                  <div className="font-semibold text-red-700 text-lg mt-3">
                    Total tributário: {formatToReal(data?.totalTributario)}
                  </div>
                )}
              </div>
              <div className="-mt-4">
                <TitleStripe className="mb-3">Dados cadastrais:</TitleStripe>
                <div className="flex flex-col md:flex-row gap-3 md:gap-20">
                  <div className="flex-1">
                    <div className="mb-3">
                      <span className="font-semibold">Documento: </span>
                      {data?.documentoConsultado}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Tipo: </span>
                      {data?.tipoPessoa}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Nome: </span>
                      {data?.nome}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Cód. Municipio: </span>
                      {data?.codigoMunicipio || "Sem info."}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">UF: </span>
                      {data?.uf || "Sem info."}
                    </div>
                  </div>
                </div>
              </div>
              <div className="-mt-4">
                <TitleStripe className="mb-3">Pendências:</TitleStripe>
                {data?.naturezas?.length > 0 ? (
                  <div className="flex gap-3 flex-wrap">
                    {data?.naturezas?.map((pendencia, index) => (
                      <div
                        key={index}
                        className="rounded shadow-md border border-gray-100 p-2 flex gap-2 bg-red-100 flex-1"
                        style={{ borderLeft: "solid 7px red" }}
                      >
                        <div className="h-20 bg-red-300 w-40 rounded flex items-center justify-center text-lg font-semibold text-red-700">
                          {formatToReal(pendencia.total)}
                        </div>
                        <div>
                          <div className="flex items-center gap-2 text-sm">
                            <div className="font-semibold">Nº inscrição:</div>
                            <div>
                              {pendencia.numeroInscricao || "sem info."}
                            </div>
                          </div>
                          <div className="flex items-center gap-2 text-sm">
                            <div className="font-semibold">Data inscrição:</div>
                            <div>{pendencia.dataInscricao || "sem info."}</div>
                          </div>
                          <div className="flex items-center gap-2 text-sm">
                            <div className="font-semibold">Natureza:</div>
                            <div>{pendencia.natureza || "sem info."}</div>
                          </div>
                          <div className="flex items-center gap-2 text-sm">
                            <div className="font-semibold">Status:</div>
                            <div>{pendencia.status || "sem info."}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Alert withBorderAccent color="green">
                    Não constam pendências
                  </Alert>
                )}
              </div>
            </Card>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </>
  );
};

export default CadinQuery;
