import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Spinner,
  Table,
  TextInput,
  Timeline,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../config/axiosInstance";
import { QUERIES_VEICULAR } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import { FiAlertOctagon } from "react-icons/fi";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
  IoMdOptions,
} from "react-icons/io";
import SliderToPreview from "../common/SliderToPreview";
import moment from "moment";
import { IoAlertCircleOutline } from "react-icons/io5";
import { formatToReal } from "../../utils/utils";
import { FaCar, FaCheckCircle } from "react-icons/fa";
import { MdBlock, MdOutlineEmail } from "react-icons/md";
import { TiArrowRight } from "react-icons/ti";
import Loader from "../common/Loader";
import FooterQuery from "./FooterQuery";

const CompletaVeicularQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const [dataCadastral, setDataCadastral] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage?.VEICULAR);
      setDataCadastral(dataFromLocalStorage?.CREDCADASTRAL);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES_VEICULAR.GET_COMPLETA_VEICULAR}/${info}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);
    let isValid = true;

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setDataCadastral(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados?.data?.VEICULAR || null);
        setDataCadastral(dados?.data?.CREDCADASTRAL || null);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getImageScoreSale = (score) => {
    const scoreLetter = {
      A: "1",
      B: "2",
      C: "3",
      D: "4",
      E: "5",
    };

    return `/assets/score/score_vehicle_${scoreLetter[score]}.png`;
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Resumo do veículo",
          "Análise de kilometragem",
          "Renajud",
          "Renafin",
          "informações do veículo",
          "ficha técnica",
          "histórico de furto e roubo",
          "dados de Leilão",
        ]}
        price={selectedPrice}
        imageBanner="/assets/bg_banner_car.jpg"
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="PLACA"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="relative overflow-hidden p-5 card-pdf">
                  <HeaderQuery title={query?.name} type="vehicle" />

                  <TitleStripe>Resumo do veículo</TitleStripe>
                  <div className="flex gap-6">
                    <img
                      className="object-contain bg-gray-200 p-2 rounded"
                      src={`data:image/png;base64, ${
                        data?.BIN_NACIONAL?.LOGO_FABRICANTE?.IMAGEM_BASE64 ||
                        data?.BIN_ESTADUAL?.LOGO_FABRICANTE?.IMAGEM_BASE64
                      }`}
                    />
                    <div className="flex gap-16">
                      <div className="text-sm">
                        <div className="mb-3">
                          <span className="font-semibold">
                            Proprietário Atual:{" "}
                          </span>
                          {data?.PROPRIETARIO_ATUAL_VEICULO
                            ?.PROPRIETARIO_NOME || "Sem info."}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">Marca/Modelo: </span>
                          {data?.BIN_NACIONAL?.MARCA_MODELO || "Sem info."}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">
                            Ano Fabr./Modelo:{" "}
                          </span>
                          {`${data?.BIN_NACIONAL?.ANO_FABRICACAO}/${data?.BIN_NACIONAL?.ANO_MODELO}` ||
                            "Indisponível"}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">Cor: </span>
                          {data?.BIN_NACIONAL?.COR_VEICULO || "Indisponível"}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">Combustível: </span>
                          {data?.BIN_NACIONAL?.COMBUSTIVEL || "Indisponível"}
                        </div>
                      </div>
                      <div className="text-sm">
                        <div className="mb-3">
                          <span className="font-semibold">Placa: </span>
                          {document}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">Chassi: </span>
                          {data?.BIN_NACIONAL?.CHASSI || "Indisponível"}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">N. Motor: </span>
                          {data?.BIN_NACIONAL?.NUMERO_MOTOR || "Indisponível"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <TitleStripe>Quadro de avisos</TitleStripe>
                  <div className="container-alerts five-columns">
                    {data?.BIN_ESTADUAL?.RESTRICOES?.DPVAT?.EXISTE_PENDENCIA ===
                    "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito DPVAT</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito DPVAT</div>
                        <div className="text-center font-semibold">
                          R$
                          {
                            data?.BIN_ESTADUAL?.RESTRICOES?.DPVAT
                              ?.VALOR_PENDENCIA
                          }
                        </div>
                      </Alert>
                    )}
                    {data?.BIN_ESTADUAL?.RESTRICOES?.IPVA?.EXISTE_PENDENCIA ===
                    "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito IPVA</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito IPVA</div>
                        <div className="text-center font-semibold">
                          {
                            data?.BIN_ESTADUAL?.RESTRICOES?.DPVAT
                              ?.VALOR_PENDENCIA
                          }
                        </div>
                      </Alert>
                    )}
                    {data?.BIN_ESTADUAL?.RESTRICOES?.LICENCIAMENTO
                      ?.EXISTE_PENDENCIA === "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito Licenciamento</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito Licenciamento</div>
                        <div className="text-center font-semibold">
                          {
                            data?.BIN_ESTADUAL?.RESTRICOES?.LICENCIAMENTO
                              ?.VALOR_PENDENCIA
                          }
                        </div>
                      </Alert>
                    )}
                    {data?.BIN_ESTADUAL?.RESTRICOES?.MULTAS
                      ?.EXISTE_PENDENCIA === "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito Multas</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito Multas</div>
                        <div className="text-center font-semibold">
                          {
                            data?.BIN_ESTADUAL?.RESTRICOES?.MULTAS
                              ?.VALOR_PENDENCIA
                          }
                        </div>
                      </Alert>
                    )}
                    {data?.GRAVAME?.STATUS_RETORNO?.CODIGO !== "0" && (
                      <>
                        {data?.GRAVAME?.QUANTIDADE_OCORRENCIAS === "0" ? (
                          <Alert
                            color="success"
                            withBorderAccent
                            className="flex justify-center items-center flex-col"
                          >
                            <IoMdCheckmarkCircleOutline
                              fontSize={30}
                              className="mx-auto mb-1"
                            />
                            <div className="text-center">Alerta de gravame</div>
                            <div className="text-center font-semibold">
                              Nada consta
                            </div>
                          </Alert>
                        ) : (
                          <Alert
                            color="failure"
                            withBorderAccent
                            className="flex justify-center items-center flex-col"
                          >
                            <FiAlertOctagon
                              fontSize={30}
                              className="mx-auto mb-1"
                            />
                            <div className="text-center">Alerta de gravame</div>
                            <div className="text-center font-semibold">
                              {data?.GRAVAME?.QUANTIDADE_OCORRENCIAS}{" "}
                              ocorrências
                            </div>
                          </Alert>
                        )}
                      </>
                    )}

                    {data?.LEILAO?.STATUS_RETORNO?.CODIGO !== "0" && (
                      <>
                        {data?.LEILAO?.QUANTIDADE_OCORRENCIAS === "0" ? (
                          <Alert
                            color="success"
                            withBorderAccent
                            className="flex justify-center items-center flex-col"
                          >
                            <IoMdCheckmarkCircleOutline
                              fontSize={30}
                              className="mx-auto mb-1"
                            />
                            <div className="text-center">Alerta de Leilão</div>
                            <div className="text-center font-semibold">
                              Nada consta
                            </div>
                          </Alert>
                        ) : (
                          <Alert
                            color="failure"
                            withBorderAccent
                            className="flex justify-center items-center flex-col"
                          >
                            <FiAlertOctagon
                              fontSize={30}
                              className="mx-auto mb-1"
                            />
                            <div className="text-center">Alerta de Leilão</div>
                            <div className="text-center font-semibold">
                              {data?.LEILAO?.QUANTIDADE_OCORRENCIAS} ocorrências
                            </div>
                          </Alert>
                        )}
                      </>
                    )}

                    {data?.RECALL?.STATUS_RETORNO?.CODIGO !== "0" && (
                      <>
                        {data?.RECALL?.QUANTIDADE_OCORRENCIAS === "0" ? (
                          <Alert
                            color="success"
                            withBorderAccent
                            className="flex justify-center items-center flex-col"
                          >
                            <IoMdCheckmarkCircleOutline
                              fontSize={30}
                              className="mx-auto mb-1"
                            />
                            <div className="text-center">RECALL</div>
                            <div className="text-center font-semibold">
                              Nada consta
                            </div>
                          </Alert>
                        ) : (
                          <Alert
                            color="failure"
                            withBorderAccent
                            className="flex justify-center items-center flex-col"
                          >
                            <FiAlertOctagon
                              fontSize={30}
                              className="mx-auto mb-1"
                            />
                            <div className="text-center">RECALL</div>
                            <div className="text-center font-semibold">
                              {data?.RECALL?.QUANTIDADE_OCORRENCIAS} ocorrências
                            </div>
                          </Alert>
                        )}
                      </>
                    )}

                    {data?.INDICIO_SINISTRO?.STATUS_RETORNO?.CODIGO !== "0" && (
                      <>
                        {data?.INDICIO_SINISTRO?.EXISTE_OCORRENCIA === "0" ? (
                          <Alert
                            color="success"
                            withBorderAccent
                            className="flex justify-center items-center flex-col"
                          >
                            <IoMdCheckmarkCircleOutline
                              fontSize={30}
                              className="mx-auto mb-1"
                            />
                            <div className="text-center">
                              Indício de Sinistro
                            </div>
                            <div className="text-center font-semibold">
                              Nada consta
                            </div>
                          </Alert>
                        ) : (
                          <Alert
                            color="failure"
                            withBorderAccent
                            className="flex justify-center items-center flex-col"
                          >
                            <FiAlertOctagon
                              fontSize={30}
                              className="mx-auto mb-1"
                            />
                            <div className="text-center">
                              Indício de Sinistro
                            </div>
                            <div className="text-center font-semibold">Sim</div>
                          </Alert>
                        )}
                      </>
                    )}

                    {data?.BIN_ESTADUAL?.RESTRICOES
                      ?.EXISTE_RESTRICAO_ROUBO_FURTO === "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Hist. roubo e furto</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Hist. roubo e furto</div>
                        <div className="text-center font-semibold">Sim</div>
                      </Alert>
                    )}

                    {data?.BIN_ESTADUAL?.RESTRICOES
                      ?.EXISTE_RESTRICAO_RENAJUD === "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">RENAJUD</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">RENAJUD</div>
                        <div className="text-center font-semibold">Sim</div>
                      </Alert>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Informações do veículo</TitleStripe>
                    <div className="flex gap-10 mt-4">
                      <div className="text-sm flex-1">
                        <div className="title-vehicle mb-3 flex gap-2 items-center">
                          <div>Base Nacional</div>
                          <img
                            className="w-5"
                            src="/assets/base_nacional.png"
                          />
                        </div>
                        <div className="p-2 rounded bg-gray-100">
                          <div className="mb-3">
                            <span className="font-semibold">
                              Marca/Modelo:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.MARCA_MODELO || "Sem info."}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Ano Fabr./Modelo:{" "}
                            </span>
                            {`${data?.BIN_NACIONAL?.ANO_FABRICACAO}/${data?.BIN_NACIONAL?.ANO_MODELO}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tempo de circulação:{" "}
                            </span>
                            {`${
                              moment().format("YYYY") -
                              +data?.BIN_NACIONAL?.ANO_FABRICACAO
                            } anos` || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Categoria: </span>
                            {data?.BIN_NACIONAL?.CATEGORIA_VEICULO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo de veículo:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.TIPO_VEICULO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Placa: </span>
                            {document || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">RENAVAM: </span>
                            {data?.BIN_NACIONAL?.RENAVAM || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Chassi: </span>
                            {data?.BIN_NACIONAL?.CHASSI || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Situação: </span>
                            {data?.BIN_NACIONAL?.SITUACAO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Câmbio: </span>
                            {data?.BIN_NACIONAL?.CAIXA_CAMBIO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Capacidade Carga:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.CAPACIDADE_CARGA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Cilindrada: </span>
                            {data?.BIN_NACIONAL?.CILINDRADA || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">CMT: </span>
                            {data?.BIN_NACIONAL?.CMT || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Combustível: </span>
                            {data?.BIN_NACIONAL?.COMBUSTIVEL || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Cor: </span>
                            {data?.BIN_NACIONAL?.COR_VEICULO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Emplacamento:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.DATA_EMPLACAMENTO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Espécie: </span>
                            {data?.BIN_NACIONAL?.ESPECIE_VEICULO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Município: </span>
                            {data?.BIN_NACIONAL?.MUNICIPIO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">UF: </span>
                            {data?.BIN_NACIONAL?.UF || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Carroceria:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.NUMERO_CARROCERIA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº DI: </span>
                            {data?.BIN_NACIONAL?.NUMERO_DI || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data registro DI:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.DATA_REGISTRO_DI ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Eixo Auxiliar:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.NUMERO_EIXO_AUXILIAR ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Eixo Traseiro:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.NUMERO_EIXO_TRASEIRO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Qtd. Eixos: </span>
                            {data?.BIN_NACIONAL?.NUMERO_EIXOS || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº Motor: </span>
                            {data?.BIN_NACIONAL?.NUMERO_MOTOR || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">PBT: </span>
                            {data?.BIN_NACIONAL?.PBT || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Potência: </span>
                            {data?.BIN_NACIONAL?.POTENCIA_VEICULO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Procedência: </span>
                            {data?.BIN_NACIONAL?.PROCEDENCIA || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Qtd. Passageiros:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.QUANTIDADE_PASSAGEIROS ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Situação do Chassi:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.SITUACAO_CHASSI ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo de Carroceria:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.TIPO_CARROCERIA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Doc. Faturado:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.TIPO_DOCUMENTO_FATURADO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Doc. Importador:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.TIPO_DOCUMENTO_IMPORTADOR ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Importador:{" "}
                            </span>
                            {data?.BIN_NACIONAL
                              ?.NUMERO_IDENTIFICACAO_IMPORTADOR ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Op. Importação:{" "}
                            </span>
                            {data?.BIN_NACIONAL
                              ?.TIPO_OPERACAO_IMPORTACAO_VEICULO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Montagem: </span>
                            {data?.BIN_NACIONAL?.TIPO_MONTAGEM ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº Faturado: </span>
                            {data?.BIN_NACIONAL
                              ?.NUMERO_IDENTIFICACAO_FATURADO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              UF Faturamento:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.UF_FATURAMENTO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Limite Rest. Tributária:{" "}
                            </span>
                            {data?.BIN_NACIONAL
                              ?.DATA_LIMITE_RESTRICAO_TRIBUTARIA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Atualização:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.DATA_ATUALIZACAO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Código Órgão SRF:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.CODIGO_DO_ORGAO_SRF ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Emissão Último CRV:{" "}
                            </span>
                            {data?.BIN_NACIONAL?.DATA_EMISSAO_ULTIMO_CRV ||
                              "Indisponível"}
                          </div>
                          <div className="title-vehicle">
                            Restrições | Nacional
                          </div>
                          {data?.BIN_NACIONAL?.RESTRICOES
                            ?.EXISTE_RESTRICAO_ROUBO_FURTO === "0" &&
                          data?.BIN_NACIONAL?.RESTRICOES?.VEICULO_BAIXADO ===
                            "0" &&
                          data?.BIN_NACIONAL?.RESTRICOES
                            ?.EXISTE_RESTRICAO_RENAJUD === "0" &&
                          data?.BIN_NACIONAL?.RESTRICOES
                            ?.EXISTE_RESTRICAO_GERAL === "0" ? (
                            <Card className={`bg-green-200 mt-2`}>
                              <div className="flex gap-2 items-center font-semibold">
                                <div>
                                  <IoMdCheckmarkCircleOutline fontSize={20} />
                                </div>
                                <div>Não constam restrições</div>
                              </div>
                            </Card>
                          ) : (
                            <div className="flex flex-col gap-2 mt-3">
                              {data?.BIN_NACIONAL?.RESTRICOES
                                ?.EXISTE_RESTRICAO_ROUBO_FURTO === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Roubo e furto</div>
                                </div>
                              )}

                              {data?.BIN_NACIONAL?.RESTRICOES
                                ?.EXISTE_RESTRICAO_RENAJUD === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: RENAJUD</div>
                                </div>
                              )}

                              {data?.BIN_NACIONAL?.RESTRICOES
                                ?.EXISTE_RESTRICAO_GERAL === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Geral</div>
                                </div>
                              )}

                              {data?.BIN_NACIONAL?.RESTRICOES
                                ?.VEICULO_BAIXADO === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Veículo Baixado</div>
                                </div>
                              )}
                            </div>
                          )}

                          <div className="title-vehicle mt-4">
                            Proprietário | Nacional
                          </div>
                          <div className="mt-3">
                            <span className="font-semibold">Nome:</span>{" "}
                            {data?.BIN_NACIONAL?.PROPRIETARIO?.NOME ||
                              "Indisponível"}
                          </div>
                          <div className="mt-2">
                            <span className="font-semibold">Documento:</span>{" "}
                            {data?.BIN_NACIONAL?.PROPRIETARIO?.DOCUMENTO ||
                              "Indisponível"}
                          </div>
                        </div>
                      </div>
                      <div className="text-sm flex-1">
                        <div className="title-vehicle mb-3">Base Estadual</div>
                        <div className="p-2 rounded bg-gray-100">
                          <div className="mb-3">
                            <span className="font-semibold">
                              Marca/Modelo:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.MARCA_MODELO || "Sem info."}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Ano Fabr./Modelo:{" "}
                            </span>
                            {`${data?.BIN_ESTADUAL?.ANO_FABRICACAO}/${data?.BIN_ESTADUAL?.ANO_MODELO}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tempo de circulação:{" "}
                            </span>
                            {`${
                              moment().format("YYYY") -
                              +data?.BIN_ESTADUAL?.ANO_FABRICACAO
                            } anos` || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Categoria: </span>
                            {data?.BIN_ESTADUAL?.CATEGORIA_VEICULO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo de veículo:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.TIPO_VEICULO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Placa: </span>
                            {document || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">RENAVAM: </span>
                            {data?.BIN_ESTADUAL?.RENAVAM || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Chassi: </span>
                            {data?.BIN_ESTADUAL?.CHASSI || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Situação: </span>
                            {data?.BIN_ESTADUAL?.SITUACAO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Câmbio: </span>
                            {data?.BIN_ESTADUAL?.CAIXA_CAMBIO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Capacidade Carga:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.CAPACIDADE_CARGA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Cilindrada: </span>
                            {data?.BIN_ESTADUAL?.CILINDRADA || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">CMT: </span>
                            {data?.BIN_ESTADUAL?.CMT || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Combustível: </span>
                            {data?.BIN_ESTADUAL?.COMBUSTIVEL || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Cor: </span>
                            {data?.BIN_ESTADUAL?.COR_VEICULO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Emplacamento:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.DATA_EMPLACAMENTO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Espécie: </span>
                            {data?.BIN_ESTADUAL?.ESPECIE_VEICULO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Município: </span>
                            {data?.BIN_ESTADUAL?.MUNICIPIO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">UF: </span>
                            {data?.BIN_ESTADUAL?.UF || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Carroceria:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.NUMERO_CARROCERIA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº DI: </span>
                            {data?.BIN_ESTADUAL?.NUMERO_DI || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data registro DI:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.DATA_REGISTRO_DI ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Eixo Auxiliar:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.NUMERO_EIXO_AUXILIAR ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Eixo Traseiro:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.NUMERO_EIXO_TRASEIRO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Qtd. Eixos: </span>
                            {data?.BIN_ESTADUAL?.NUMERO_EIXOS || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº Motor: </span>
                            {data?.BIN_ESTADUAL?.NUMERO_MOTOR || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">PBT: </span>
                            {data?.BIN_ESTADUAL?.PBT || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Potência: </span>
                            {data?.BIN_ESTADUAL?.POTENCIA_VEICULO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Procedência: </span>
                            {data?.BIN_ESTADUAL?.PROCEDENCIA || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Qtd. Passageiros:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.QUANTIDADE_PASSAGEIROS ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Situação do Chassi:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.SITUACAO_CHASSI ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo de Carroceria:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.TIPO_CARROCERIA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Doc. Faturado:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.TIPO_DOCUMENTO_FATURADO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Doc. Importador:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.TIPO_DOCUMENTO_IMPORTADOR ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Importador:{" "}
                            </span>
                            {data?.BIN_ESTADUAL
                              ?.NUMERO_IDENTIFICACAO_IMPORTADOR ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Op. Importação:{" "}
                            </span>
                            {data?.BIN_ESTADUAL
                              ?.TIPO_OPERACAO_IMPORTACAO_VEICULO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Montagem: </span>
                            {data?.BIN_ESTADUAL?.TIPO_MONTAGEM ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº Faturado: </span>
                            {data?.BIN_ESTADUAL
                              ?.NUMERO_IDENTIFICACAO_FATURADO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              UF Faturamento:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.UF_FATURAMENTO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Limite Rest. Tributária:{" "}
                            </span>
                            {data?.BIN_ESTADUAL
                              ?.DATA_LIMITE_RESTRICAO_TRIBUTARIA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Atualização:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.DATA_ATUALIZACAO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Código Órgão SRF:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.CODIGO_DO_ORGAO_SRF ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Emissão Último CRV:{" "}
                            </span>
                            {data?.BIN_ESTADUAL?.DATA_EMISSAO_ULTIMO_CRV ||
                              "Indisponível"}
                          </div>
                          <div className="title-vehicle">
                            Restrições | Estadual
                          </div>
                          {data?.BIN_ESTADUAL?.RESTRICOES
                            ?.EXISTE_RESTRICAO_ROUBO_FURTO === "0" &&
                          data?.BIN_ESTADUAL?.RESTRICOES?.VEICULO_BAIXADO ===
                            "0" &&
                          data?.BIN_ESTADUAL?.RESTRICOES
                            ?.EXISTE_RESTRICAO_RENAJUD === "0" &&
                          data?.BIN_ESTADUAL?.RESTRICOES
                            ?.EXISTE_RESTRICAO_GERAL === "0" ? (
                            <Card className={`bg-green-200 mt-2`}>
                              <div className="flex gap-2 items-center font-semibold">
                                <div>
                                  <IoMdCheckmarkCircleOutline fontSize={20} />
                                </div>
                                <div>Não constam restrições</div>
                              </div>
                            </Card>
                          ) : (
                            <div className="flex flex-col gap-2 mt-3">
                              {data?.BIN_ESTADUAL?.RESTRICOES
                                ?.EXISTE_RESTRICAO_ROUBO_FURTO === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Roubo e furto</div>
                                </div>
                              )}

                              {data?.BIN_ESTADUAL?.RESTRICOES
                                ?.EXISTE_RESTRICAO_RENAJUD === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: RENAJUD</div>
                                </div>
                              )}

                              {data?.BIN_ESTADUAL?.RESTRICOES
                                ?.EXISTE_RESTRICAO_GERAL === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Geral</div>
                                </div>
                              )}

                              {data?.BIN_ESTADUAL?.RESTRICOES
                                ?.VEICULO_BAIXADO === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Veículo Baixado</div>
                                </div>
                              )}
                            </div>
                          )}
                          <div className="title-vehicle mt-4">
                            Multas e débitos | Estadual
                          </div>
                          <div className="flex gap-2 items-center font-semibold mt-3">
                            {data?.BIN_ESTADUAL?.RESTRICOES?.DPVAT
                              ?.EXISTE_PENDENCIA === "1" ? (
                              <MdBlock color="red" />
                            ) : (
                              <IoMdCheckmarkCircleOutline color="green" />
                            )}

                            <div>Débitos: DPVAT</div>
                            {data?.BIN_ESTADUAL?.RESTRICOES?.DPVAT
                              ?.EXISTE_PENDENCIA === "1" && (
                              <div>
                                -{" "}
                                {
                                  data?.BIN_ESTADUAL?.RESTRICOES?.DPVAT
                                    ?.VALOR_PENDENCIA
                                }
                              </div>
                            )}
                          </div>
                          <div className="flex gap-2 items-center font-semibold mt-3">
                            {data?.BIN_ESTADUAL?.RESTRICOES?.IPVA
                              ?.EXISTE_PENDENCIA === "1" ? (
                              <MdBlock color="red" />
                            ) : (
                              <IoMdCheckmarkCircleOutline color="green" />
                            )}
                            <div>Débitos: IPVA</div>
                            {data?.BIN_ESTADUAL?.RESTRICOES?.IPVA
                              ?.EXISTE_PENDENCIA === "1" && (
                              <div>
                                -{" "}
                                {
                                  data?.BIN_ESTADUAL?.RESTRICOES?.IPVA
                                    ?.VALOR_PENDENCIA
                                }
                              </div>
                            )}
                          </div>
                          <div className="flex gap-2 items-center font-semibold mt-3">
                            {data?.BIN_ESTADUAL?.RESTRICOES?.LICENCIAMENTO
                              ?.EXISTE_PENDENCIA === "1" ? (
                              <MdBlock color="red" />
                            ) : (
                              <IoMdCheckmarkCircleOutline color="green" />
                            )}
                            <div>Débitos: Licenciamento</div>
                            {data?.BIN_ESTADUAL?.RESTRICOES?.LICENCIAMENTO
                              ?.EXISTE_PENDENCIA === "1" && (
                              <div>
                                -{" "}
                                {
                                  data?.BIN_ESTADUAL?.RESTRICOES?.LICENCIAMENTO
                                    ?.VALOR_PENDENCIA
                                }
                              </div>
                            )}
                          </div>
                          <div className="flex gap-2 items-center font-semibold mt-3">
                            {data?.BIN_ESTADUAL?.RESTRICOES?.MULTAS
                              ?.EXISTE_PENDENCIA === "1" ? (
                              <MdBlock color="red" />
                            ) : (
                              <IoMdCheckmarkCircleOutline color="green" />
                            )}
                            <div>Débitos: Multas</div>
                            {data?.BIN_ESTADUAL?.RESTRICOES?.MULTAS
                              ?.EXISTE_PENDENCIA === "1" && (
                              <div>
                                -{" "}
                                {
                                  data?.BIN_ESTADUAL?.RESTRICOES?.MULTAS
                                    ?.VALOR_PENDENCIA
                                }
                              </div>
                            )}
                          </div>

                          <div className="title-vehicle mt-4">
                            Proprietário | Estadual
                          </div>
                          <div className="mt-3">
                            <span className="font-semibold">Nome:</span>{" "}
                            {data?.BIN_ESTADUAL?.PROPRIETARIO?.NOME ||
                              "Indisponível"}
                          </div>
                          <div className="mt-2">
                            <span className="font-semibold">Documento:</span>{" "}
                            {data?.BIN_ESTADUAL?.PROPRIETARIO?.DOCUMENTO ||
                              "Indisponível"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <TitleStripe>Análise de kilometragem</TitleStripe>
                    {data?.ANALISE_KILOMETRAGEM?.STATUS_RETORNO?.CODIGO ===
                    "1" ? (
                      <div className="flex gap-10 mt-4">
                        <div className="bg-green-300 p-4 rounded">
                          <div className="font-semibold">Baixa</div>
                          <Badge className="text-base" color="green">
                            {data?.ANALISE_KILOMETRAGEM?.ESTIMATIVA?.BAIXA}
                          </Badge>
                        </div>
                        <div className="bg-orange-300 p-4 rounded">
                          <div className="font-semibold">Média</div>
                          <Badge className="text-base" color="warning">
                            {data?.ANALISE_KILOMETRAGEM?.ESTIMATIVA?.MEDIA}
                          </Badge>
                        </div>
                        <div className="bg-red-300 p-4 rounded">
                          <div className="font-semibold">Alta</div>
                          <Badge className="text-base" color="failure">
                            {data?.ANALISE_KILOMETRAGEM?.ESTIMATIVA?.ALTA}
                          </Badge>
                        </div>
                      </div>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Ficha Técnica </TitleStripe>
                    <div className="mt-4">
                      <div className="title-vehicle">Informações técnicas</div>
                      <div className="flex gap-12 mt-3 rounded bg-gray-100 p-2 mb-3">
                        <div className="text-sm min-w-72">
                          <div className="mb-3">
                            <span className="font-semibold">Câmbio: </span>
                            {data?.FICHA_TECNICA_VEICULAR?.CAMBIO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Direção: </span>
                            {data?.FICHA_TECNICA_VEICULAR?.DIRECAO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Motor: </span>
                            {data?.FICHA_TECNICA_VEICULAR?.MOTOR ||
                              "Indisponível"}
                          </div>
                          <div>
                            <span className="font-semibold">
                              Quantidade Passageiros:{" "}
                            </span>
                            {data?.FICHA_TECNICA_VEICULAR
                              ?.QUANTIDADE_PASSAGEIROS || "Indisponível"}
                          </div>
                        </div>
                        <div className="text-sm">
                          <div className="mb-3">
                            <span className="font-semibold">
                              Quantidade Portas:{" "}
                            </span>
                            {data?.FICHA_TECNICA_VEICULAR?.QUANTIDADE_PORTAS ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Suspensão Dianteira:{" "}
                            </span>
                            {data?.FICHA_TECNICA_VEICULAR
                              ?.SUSPENSAO_DIANTEIRA || "Indisponível"}
                          </div>
                          <div>
                            <span className="font-semibold">
                              Suspensão Traseira:{" "}
                            </span>
                            {data?.FICHA_TECNICA_VEICULAR?.SUSPENSAO_TRASEIRA ||
                              "Indisponível"}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="title-vehicle">Medidas</div>
                      <div className="flex gap-12 mt-3 rounded bg-gray-100 p-2 mb-3">
                        <div className="text-sm min-w-72">
                          <div className="mb-3">
                            <span className="font-semibold">Altura: </span>
                            {data?.FICHA_TECNICA_VEICULAR?.MEDIDAS?.ALTURA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Comprimento: </span>
                            {data?.FICHA_TECNICA_VEICULAR?.MEDIDAS
                              ?.COMPRIMENTO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Largura: </span>
                            {data?.FICHA_TECNICA_VEICULAR?.MEDIDAS?.LARGURA ||
                              "Indisponível"}
                          </div>
                          <div>
                            <span className="font-semibold">Entre Eixos: </span>
                            {data?.FICHA_TECNICA_VEICULAR.MEDIDAS
                              ?.ENTRE_EIXOS || "Indisponível"}
                          </div>
                        </div>
                        <div className="text-sm">
                          <div className="mb-3">
                            <span className="font-semibold">Peso: </span>
                            {data?.FICHA_TECNICA_VEICULAR?.MEDIDAS?.PESO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Porta Malas: </span>
                            {data?.FICHA_TECNICA_VEICULAR.MEDIDAS
                              ?.PORTA_MALAS || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Tanque: </span>
                            {data?.FICHA_TECNICA_VEICULAR?.MEDIDAS
                              ?.TANQUE_COMBUSTIVEL || "Indisponível"}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="title-vehicle mb-3">
                        Medição Combustível
                      </div>
                      {data?.FICHA_TECNICA_VEICULAR?.MEDICAO_COMBUSTIVEL
                        ?.length > 0 ? (
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Combustível
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Potência
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Clindr.
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Vel. Max.
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Torque
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Cons. Cidade
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Cons. Estrada
                            </Table.HeadCell>
                          </Table.Head>
                          <Table.Body className="divide-y">
                            {data?.FICHA_TECNICA_VEICULAR?.MEDICAO_COMBUSTIVEL?.map(
                              (medicao, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                    {medicao.COMBUSTIVEL}
                                  </Table.Cell>
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                    {medicao.POTENCIA}
                                  </Table.Cell>
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                    {medicao.CILINDRADAS}
                                  </Table.Cell>
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                    {medicao.VELOCIDADE_MAXIMA}
                                  </Table.Cell>
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                    {medicao.TORQUE}
                                  </Table.Cell>
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                    {medicao.CONSUMO_CIDADE}
                                  </Table.Cell>
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                    {medicao.CONSUMO_ESTRADA}
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      ) : (
                        <Card className={`bg-orange-200 mt-4`}>
                          <div className="flex gap-2 items-center font-semibold text-gray-700">
                            <div>
                              <IoAlertCircleOutline fontSize={20} />
                            </div>
                            <div>Sem informação da medição</div>
                          </div>
                        </Card>
                      )}
                    </div>

                    <div className="title-vehicle mt-4">Equipamentos</div>
                    {!data?.FICHA_TECNICA_VEICULAR?.EQUIPAMENTOS?.length ===
                    0 ? (
                      <>
                        <div className="container-alerts three-columns mt-4">
                          {data?.FICHA_TECNICA_VEICULAR?.EQUIPAMENTOS?.length >
                            0 &&
                            data?.FICHA_TECNICA_VEICULAR?.EQUIPAMENTOS?.map(
                              (equipamento, index) => (
                                <div
                                  className={`rounded px-2 py-3 flex items-center text-sm justify-center gap-2 text-center text-xs font-semibold capitalize border border-gray-300 ${
                                    equipamento?.DISPONIBILIDADE?.toUpperCase() ===
                                    "DISPONIVEL"
                                      ? "text-green-500"
                                      : equipamento?.DISPONIBILIDADE?.toUpperCase() ===
                                        "OPCIONAL"
                                      ? "text-blue-500"
                                      : "text-red-500"
                                  }`}
                                  key={index}
                                >
                                  {equipamento?.DISPONIBILIDADE?.toUpperCase() ===
                                  "DISPONIVEL" ? (
                                    <IoMdCheckmarkCircleOutline
                                      className="text-green-500"
                                      fontSize={20}
                                    />
                                  ) : (
                                    <>
                                      {equipamento?.DISPONIBILIDADE?.toUpperCase() ===
                                      "OPCIONAL" ? (
                                        <IoMdOptions
                                          className="text-blue-500"
                                          fontSize={20}
                                        />
                                      ) : (
                                        <IoMdCloseCircleOutline
                                          className="text-red-500"
                                          fontSize={20}
                                        />
                                      )}
                                    </>
                                  )}
                                  {equipamento.DESCRICAO?.toLowerCase()}
                                </div>
                              )
                            )}
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold text-gray-700">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação dos equipamentos</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Histórico de Roubo e Furto</TitleStripe>
                    {data?.HISTORICO_ROUBO_FURTO?.STATUS_RETORNO?.CODIGO ===
                    "1" ? (
                      <>
                        {data?.HISTORICO_ROUBO_FURTO?.INDICADOR
                          ?.HOUVE_DECLARACAO_DE_ROUBO_FURTO === "0" &&
                        data?.HISTORICO_ROUBO_FURTO?.INDICADOR
                          ?.HOUVE_RECUPERACAO_DE_ROUBO_FURTO === "0" &&
                        data?.HISTORICO_ROUBO_FURTO?.INDICADOR
                          ?.HOUVE_DEVOLUCAO_DE_ROUBO_FURTO === "0" ? (
                          <Card className={`bg-green-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoMdCheckmarkCircleOutline fontSize={20} />
                              </div>
                              <div>
                                Nenhum registro de roubo e furto encontrado!
                              </div>
                            </div>
                          </Card>
                        ) : (
                          <Table striped className="w-full mt-3 text-sm">
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Infração
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Órgão
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Exigibilidade
                              </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                              {data?.HISTORICO_ROUBO_FURTO?.OCORRENCIAS?.map(
                                (historico, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {"-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {"-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {"-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {"-"}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Renajud</TitleStripe>
                    {data?.RENAJUD?.STATUS_RETORNO?.CODIGO === "1" ? (
                      <>
                        {data?.RENAJUD?.QUANTIDADE_OCORRENCIAS === "0" ? (
                          <Card className={`bg-green-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoMdCheckmarkCircleOutline fontSize={20} />
                              </div>
                              <div>Nenhum registro de RENAJUD encontrado!</div>
                            </div>
                          </Card>
                        ) : (
                          <Card className={`bg-red-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoAlertCircleOutline fontSize={20} />
                              </div>
                              <div>{data?.RENAJUD?.MSG_ALERTA}</div>
                            </div>
                          </Card>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Certificado de Segurança Veicular</TitleStripe>
                    {data?.CSV?.STATUS_RETORNO?.CODIGO === "1" ? (
                      <>
                        {data?.CSV?.QUANTIDADE_OCORRENCIA === "0" ? (
                          <Card className={`bg-orange-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoAlertCircleOutline fontSize={20} />
                              </div>
                              <div>Não constam registros para exibição</div>
                            </div>
                          </Card>
                        ) : (
                          <Card className={`bg-red-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoAlertCircleOutline fontSize={20} />
                              </div>
                              <div>
                                {data?.CSV?.OCORRENCIAS?.length} ocorrências
                              </div>
                            </div>
                          </Card>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>
                      Registro Nacional de Infrações de Trânsito
                    </TitleStripe>
                    {data?.RENAINF?.STATUS_RETORNO?.CODIGO === "1" ? (
                      <>
                        {data?.RENAINF?.QTD_OCORRENCIAS === "0" ? (
                          <Card className={`bg-green-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoMdCheckmarkCircleOutline fontSize={20} />
                              </div>
                              <div>Nenhum registro de RENAINF encontrado!</div>
                            </div>
                          </Card>
                        ) : (
                          <Table striped className="w-full mt-3 text-sm">
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data Infr.
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Infração
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Órgão
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Exigibilidade
                              </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                              {data?.RENAINF?.OCORRENCIAS?.map(
                                (infracao, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {infracao?.DATA_INFRACAO || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {infracao?.INFRACAO || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {infracao?.ORGAO_ATUADOR || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {infracao?.EXIGIBILIDADE || "-"}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Indício de Sinistro</TitleStripe>
                    {data?.INDICIO_SINISTRO?.STATUS_RETORNO?.CODIGO === "1" ? (
                      <>
                        {data?.INDICIO_SINISTRO?.EXISTE_OCORRENCIA === "0" ? (
                          <Card className={`bg-green-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoMdCheckmarkCircleOutline fontSize={20} />
                              </div>
                              <div>Nenhum Indício de Sinistro encontrado!</div>
                            </div>
                          </Card>
                        ) : (
                          <Card className={`bg-red-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoAlertCircleOutline fontSize={20} />
                              </div>
                              <div>
                                {data?.INDICIO_SINISTRO?.DESCRICAO_OCORRENCIA ||
                                  "Constam ocorrências"}
                              </div>
                            </div>
                          </Card>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Indício de Sinistro - Base I</TitleStripe>
                    {data?.INDICIO_SINISTRO_CONJUGADO?.STATUS_RETORNO
                      ?.CODIGO === "1" &&
                    data?.INDICIO_SINISTRO_CONJUGADO?.OCORRENCIAS[0]
                      ?.STATUS_RETORNO?.CODIGO === "1" ? (
                      <>
                        {data?.INDICIO_SINISTRO_CONJUGADO?.OCORRENCIAS[0]
                          ?.EXISTE_OCORRENCIA === "0" ? (
                          <Card className={`bg-green-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoMdCheckmarkCircleOutline fontSize={20} />
                              </div>
                              <div>
                                Nenhum Indício de Sinistro - Base I encontrado!
                              </div>
                            </div>
                          </Card>
                        ) : (
                          <Card className={`bg-red-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoAlertCircleOutline fontSize={20} />
                              </div>
                              <div>
                                {data?.INDICIO_SINISTRO_CONJUGADO
                                  ?.OCORRENCIAS[0]?.DESCRICAO_OCORRENCIA ||
                                  "Constam ocorrências"}
                              </div>
                            </div>
                          </Card>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Dados de Leilões Realizados</TitleStripe>
                    {data?.LEILAO?.STATUS_RETORNO?.CODIGO === "1" ? (
                      <>
                        {data?.LEILAO?.QUANTIDADE_OCORRENCIAS === "0" ? (
                          <Card className={`bg-green-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoMdCheckmarkCircleOutline fontSize={20} />
                              </div>
                              <div>Nenhum registro de leilão realizado!</div>
                            </div>
                          </Card>
                        ) : (
                          <div>
                            <div className="title-vehicle mt-3">
                              Dados do leilão
                            </div>
                            <Table striped className="w-full mt-3 text-sm">
                              <Table.Head>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Leiloeiro
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Data
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Lote
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Pátio
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Situação Chassi
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Condição Geral
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Comitente
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {data?.LEILAO?.OCORRENCIAS?.map(
                                  (leilao, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.LEILOEIRO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.DATA_LEILAO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.LOTE || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.PATIO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.SITUACAO_CHASSI || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.CONDICAO_GERAL_VEICULO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.COMITENTE || "-"}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>

                            <div className="title-vehicle mt-5">
                              Itens verificados no leilão
                            </div>
                            <ul className="flex flex-wrap gap-4 mt-3">
                              {[
                                "Classificação de avaria",
                                "Classificação para aceite securitário",
                                "Classificação de probabilidade de vistoria",
                                "Sinistro de pequena monta",
                                "Sinistro de média monta",
                                "Sinistro de grande monta",
                                "Leilões presenciais",
                                "Leilões on-line",
                                "Leilões judiciais",
                                "Leilões de seguradoras",
                                "Leilões de bancos e financeiras",
                                "Leilões de empresas",
                              ].map((item, index) => (
                                <li
                                  key={index}
                                  className="flex gap-2 items-center bg-green-200 rounded p-1 text-sm"
                                >
                                  <FaCheckCircle color="green" />
                                  {item}
                                </li>
                              ))}
                            </ul>

                            <div className="title-vehicle mt-5">Score</div>

                            <img
                              className="mx-auto my-4 max-h-40"
                              src={getImageScoreSale(
                                data?.LEILAO?.SCORE?.PONTUACAO?.toUpperCase()
                              )}
                            />

                            <div className="title-vehicle mt-5">
                              Aceitação de Seguro
                            </div>
                            <div className="mt-2 text-sm bg-gray-100 rounded p-2 italic">
                              Com base em estudo estatístico, a régua aponta a
                              probabilidade de 0% a 100% do veículo ser segurado
                              nas principais seguradoras do país.
                            </div>
                            <div className="square">
                              <FaCar
                                fontSize={28}
                                style={{
                                  left: `${
                                    data?.LEILAO?.SCORE?.ACEITACAO === "100"
                                      ? +data?.LEILAO?.SCORE?.ACEITACAO - 4
                                      : data?.LEILAO?.SCORE?.ACEITACAO
                                  }%`,
                                }}
                              />
                            </div>
                            <div className="font-bold flex justify-end -mt-4">
                              {data?.LEILAO?.SCORE?.ACEITACAO}%
                            </div>
                            <div className="title-vehicle mt-5">
                              Percentual sobre a Tabela de Referência
                            </div>
                            <div className="mt-2 text-sm bg-gray-100 rounded p-2 italic">
                              Com base em estudo estatístico, a régua aponta o
                              possível percentual da tabela de referência (fipe)
                              utilizada pela seguradora no momento da
                              contratação do seguro do bem.
                            </div>
                            <div className="square">
                              <FaCar
                                fontSize={28}
                                style={{
                                  left: `${
                                    data?.LEILAO?.SCORE
                                      ?.PERCENTUAL_SOBRE_TABELA_REFERENCIA ===
                                    "100"
                                      ? +data?.LEILAO?.SCORE
                                          ?.PERCENTUAL_SOBRE_TABELA_REFERENCIA -
                                        4
                                      : data?.LEILAO?.SCORE
                                          ?.PERCENTUAL_SOBRE_TABELA_REFERENCIA
                                  }%`,
                                }}
                              />
                            </div>
                            <div className="font-bold flex justify-end -mt-4">
                              {
                                data?.LEILAO?.SCORE
                                  ?.PERCENTUAL_SOBRE_TABELA_REFERENCIA
                              }
                              %
                            </div>
                            <div className="title-vehicle mt-5">
                              Necessidade de Vistoria Especial
                            </div>
                            <div className="mt-2 text-sm bg-gray-100 rounded p-2 italic">
                              Com base em estudo estatístico, a régua aponta a
                              menor ou maior probabilidade de exigência de
                              vistoria especial por parte da seguradora.
                            </div>
                            <div className="square">
                              <FaCar
                                fontSize={28}
                                style={{
                                  left: `${data?.LEILAO?.SCORE?.PERCENTUAL_SOBRE_TABELA_REFERENCIA}%`,
                                }}
                              />
                            </div>
                            <div className="font-bold flex justify-end -mt-4">
                              {data?.LEILAO?.SCORE?.EXIGE_VISTORIA_ESPECIAL}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Dados do Leilão - Base I</TitleStripe>
                    {data?.LEILAO_CONJUGADO?.STATUS_RETORNO?.CODIGO === "1" &&
                    data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]?.STATUS_RETORNO
                      ?.CODIGO === "1" ? (
                      <>
                        {data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]
                          ?.QUANTIDADE_OCORRENCIAS === "0" ? (
                          <Card className={`bg-green-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoMdCheckmarkCircleOutline fontSize={20} />
                              </div>
                              <div>
                                Nenhum registro de leilão Base I encontrado!
                              </div>
                            </div>
                          </Card>
                        ) : (
                          <div>
                            <div className="title-vehicle mt-3">
                              Dados do leilão
                            </div>
                            <Table striped className="w-full mt-3 text-sm">
                              <Table.Head>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Leiloeiro
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Data
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Lote
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Pátio
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Situação Chassi
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Condição Geral
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Comitente
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]?.OCORRENCIAS?.map(
                                  (leilao, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.LEILOEIRO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.DATA_LEILAO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.LOTE || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.PATIO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.SITUACAO_CHASSI || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.CONDICAO_GERAL_VEICULO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {leilao?.COMITENTE || "-"}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>

                            <div className="title-vehicle mt-5">Score</div>

                            <img
                              className="mx-auto my-4 max-h-40"
                              src={getImageScoreSale(
                                data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]?.SCORE?.PONTUACAO?.toUpperCase()
                              )}
                            />

                            <div className="title-vehicle mt-5">
                              Aceitação de Seguro
                            </div>
                            <div className="mt-2 text-sm bg-gray-100 rounded p-2 italic">
                              Com base em estudo estatístico, a régua aponta a
                              probabilidade de 0% a 100% do veículo ser segurado
                              nas principais seguradoras do país.
                            </div>
                            <div className="square">
                              <FaCar
                                fontSize={28}
                                style={{
                                  left: `${
                                    data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]
                                      ?.SCORE?.ACEITACAO === "100"
                                      ? +data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]
                                          ?.SCORE?.ACEITACAO - 4
                                      : data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]
                                          ?.SCORE?.ACEITACAO
                                  }%`,
                                }}
                              />
                            </div>
                            <div className="font-bold flex justify-end -mt-4">
                              {
                                data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]?.SCORE
                                  ?.ACEITACAO
                              }
                              %
                            </div>
                            <div className="title-vehicle mt-5">
                              Percentual sobre a Tabela de Referência
                            </div>
                            <div className="mt-2 text-sm bg-gray-100 rounded p-2 italic">
                              Com base em estudo estatístico, a régua aponta o
                              possível percentual da tabela de referência (fipe)
                              utilizada pela seguradora no momento da
                              contratação do seguro do bem.
                            </div>
                            <div className="square">
                              <FaCar
                                fontSize={28}
                                style={{
                                  left: `${
                                    data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]
                                      ?.SCORE
                                      ?.PERCENTUAL_SOBRE_TABELA_REFERENCIA ===
                                    "100"
                                      ? +data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]
                                          ?.SCORE
                                          ?.PERCENTUAL_SOBRE_TABELA_REFERENCIA -
                                        4
                                      : data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]
                                          ?.SCORE
                                          ?.PERCENTUAL_SOBRE_TABELA_REFERENCIA
                                  }%`,
                                }}
                              />
                            </div>
                            <div className="font-bold flex justify-end -mt-4">
                              {
                                data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]?.SCORE
                                  ?.PERCENTUAL_SOBRE_TABELA_REFERENCIA
                              }
                              %
                            </div>
                            <div className="title-vehicle mt-5">
                              Necessidade de Vistoria Especial
                            </div>
                            <div className="mt-2 text-sm bg-gray-100 rounded p-2 italic">
                              Com base em estudo estatístico, a régua aponta a
                              menor ou maior probabilidade de exigência de
                              vistoria especial por parte da seguradora.
                            </div>
                            <div className="square">
                              <FaCar
                                fontSize={28}
                                style={{
                                  left: `${
                                    data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]
                                      ?.SCORE
                                      ?.PERCENTUAL_SOBRE_TABELA_REFERENCIA ===
                                    "100"
                                      ? +data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]
                                          ?.SCORE
                                          ?.PERCENTUAL_SOBRE_TABELA_REFERENCIA -
                                        4
                                      : data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]
                                          ?.SCORE
                                          ?.PERCENTUAL_SOBRE_TABELA_REFERENCIA
                                  }%`,
                                }}
                              />
                            </div>
                            <div className="font-bold flex justify-end -mt-4">
                              {
                                data?.LEILAO_CONJUGADO?.OCORRENCIAS[0]?.SCORE
                                  ?.EXIGE_VISTORIA_ESPECIAL
                              }
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Histórico de Proprietários</TitleStripe>
                    {data?.HISTORICO_PROPRIETARIOS?.STATUS_RETORNO?.CODIGO ===
                    "1" ? (
                      <>
                        {data?.HISTORICO_PROPRIETARIOS
                          ?.QUANTIDADE_OCORRENCIAS === "0" ? (
                          <Card className={`bg-orange-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoMdCheckmarkCircleOutline fontSize={20} />
                              </div>
                              <div>
                                Nenhum registro de histórico de Proprietários
                                encontrado!
                              </div>
                            </div>
                          </Card>
                        ) : (
                          <>
                            <Timeline className="mt-3">
                              {data?.HISTORICO_PROPRIETARIOS?.OCORRENCIAS?.map(
                                (proprietario, index) => (
                                  <Timeline.Item key={index} className="mb-2">
                                    <Timeline.Point />
                                    <Timeline.Content>
                                      <Timeline.Time>
                                        Placa: {proprietario.PLACA}
                                      </Timeline.Time>
                                      <Timeline.Title className="text-base">
                                        Nome: {proprietario.NOME_PROPRIETARIO}
                                      </Timeline.Title>
                                      <Timeline.Body className="text-sm mb-0">
                                        <div>
                                          Documento:{" "}
                                          {proprietario.DOCUMENTO ||
                                            "Sem info."}
                                        </div>
                                      </Timeline.Body>
                                    </Timeline.Content>
                                  </Timeline.Item>
                                )
                              )}
                            </Timeline>
                          </>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Precificador</TitleStripe>
                    {data?.PRECIFICADOR?.STATUS_RETORNO?.CODIGO === "1" ? (
                      <>
                        {data?.PRECIFICADOR?.OCORRENCIAS?.length === 0 ? (
                          <Card className={`bg-orange-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoMdCheckmarkCircleOutline fontSize={20} />
                              </div>
                              <div>Nenhum registro de preço deste modelo!</div>
                            </div>
                          </Card>
                        ) : (
                          <>
                            <Table striped className="w-full mt-3 text-sm">
                              <Table.Head>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Fabr./Modelo
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Ano
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Informante
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Código
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Preço
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Preço Médio
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300">
                                  Vigência
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {data?.PRECIFICADOR?.OCORRENCIAS?.map(
                                  (precificador, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {precificador?.FABRICANTE_MODELO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {precificador?.ANO_MODELO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {precificador?.INFORMANTE || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {precificador?.CODIGO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        <Badge className="w-fit mx-auto">
                                          {formatToReal(
                                            precificador?.PRECO?.replace(
                                              ",",
                                              "."
                                            )
                                          ) || "-"}
                                        </Badge>
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {precificador?.PRECO_MEDIO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {precificador?.VIGENCIA || "-"}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>
                          </>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Recall do Veículo</TitleStripe>
                    {data?.RECALL?.STATUS_RETORNO?.CODIGO === "1" ? (
                      <>
                        {data?.RECALL?.QUANTIDADE_OCORRENCIAS === "0" ? (
                          <Card className={`bg-green-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoMdCheckmarkCircleOutline fontSize={20} />
                              </div>
                              <div>Nenhum RECALL encontrado!</div>
                            </div>
                          </Card>
                        ) : (
                          <Card className={`bg-red-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoAlertCircleOutline fontSize={20} />
                              </div>
                              <div>
                                {data?.RECALL?.QUANTIDADE_OCORRENCIAS}{" "}
                                ocorrências
                              </div>
                            </div>
                          </Card>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Remarketing</TitleStripe>
                    {data?.REMARKETING?.STATUS_RETORNO?.CODIGO === "1" ? (
                      <>
                        {data?.REMARKETING?.QUANTIDADE_OCORRENCIAS === "0" ? (
                          <Card className={`bg-green-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoMdCheckmarkCircleOutline fontSize={20} />
                              </div>
                              <div>Nenhum Remarketing encontrado!</div>
                            </div>
                          </Card>
                        ) : (
                          <Card className={`bg-red-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoAlertCircleOutline fontSize={20} />
                              </div>
                              <div>
                                {data?.REMARKETING?.QUANTIDADE_OCORRENCIAS}{" "}
                                ocorrências
                              </div>
                            </div>
                          </Card>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>
                      Dados Cadastrais do Proprietário Atual
                    </TitleStripe>
                    {dataCadastral?.DADOS_RECEITA_FEDERAL?.STATUS_RETORNO
                      ?.CODIGO === "1" ? (
                      <>
                        <div className="flex gap-16 mt-3">
                          <div className="text-sm">
                            <div className="mb-3">
                              <span className="font-semibold">Nome: </span>
                              {dataCadastral?.DADOS_RECEITA_FEDERAL?.NOME ||
                                "Sem info."}
                            </div>
                            <div className="mb-3">
                              <span className="font-semibold">
                                Dt. Nascimento:{" "}
                              </span>
                              {dataCadastral?.DADOS_RECEITA_FEDERAL
                                ?.DATA_NASCIMENTO_FUNDACAO || "Indisponível"}
                            </div>
                            <div className="mb-3">
                              <span className="font-semibold">
                                Nome da mãe:{" "}
                              </span>
                              {dataCadastral?.DADOS_RECEITA_FEDERAL?.NOME_MAE ||
                                "Indisponível"}
                            </div>
                          </div>
                          <div className="text-sm">
                            <div className="mb-3">
                              <span className="font-semibold">Situação: </span>
                              {
                                dataCadastral?.DADOS_RECEITA_FEDERAL
                                  ?.SITUACAO_RECEITA
                              }
                            </div>
                            <div className="mb-3">
                              <span className="font-semibold">
                                Dt. Atualização:{" "}
                              </span>
                              {dataCadastral?.DADOS_RECEITA_FEDERAL
                                ?.DATA_ATUALIZACAO || "Indisponível"}
                            </div>
                            <div className="mb-3">
                              <span className="font-semibold">Sexo: </span>
                              {dataCadastral?.DADOS_RECEITA_FEDERAL?.SEXO ||
                                "Indisponível"}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>
                      Informações, Alertas ou Restrições
                    </TitleStripe>
                    {dataCadastral?.INFORMACOES_ALERTAS_RESTRICOES
                      ?.STATUS_RETORNO?.CODIGO === "1" ? (
                      <ul className=" mt-3 text-sm flex flex-col gap-2">
                        {dataCadastral?.INFORMACOES_ALERTAS_RESTRICOES?.OCORRENCIAS?.map(
                          (ocorrencia, index) => (
                            <li
                              key={index}
                              className="bg-gray-100 px-2 py-1 rounded flex gap-1"
                            >
                              <div className="font-semibold capitalize">
                                {ocorrencia.TITULO?.toLowerCase()}
                              </div>
                              <div className="flex items-center gap-1 capitalize">
                                <TiArrowRight />
                                {ocorrencia.OBSERVACOES?.toLowerCase()}
                              </div>
                            </li>
                          )
                        )}
                      </ul>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Renda Presumida</TitleStripe>
                    {dataCadastral?.RENDA_PRESUMIDA?.STATUS_RETORNO?.CODIGO ===
                    "1" ? (
                      <Alert withBorderAccent className="mt-3">
                        <div className="text-base">
                          Faixa:{" "}
                          <strong>
                            {dataCadastral?.RENDA_PRESUMIDA?.FAIXA}
                          </strong>
                        </div>
                        <div className="capitalize">
                          Observações:{" "}
                          {dataCadastral?.RENDA_PRESUMIDA?.MENSAGEM?.toLowerCase()}
                        </div>
                      </Alert>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>E-mails de Contato</TitleStripe>
                    {dataCadastral?.EMAILS?.STATUS_RETORNO?.CODIGO === "1" ? (
                      <>
                        {dataCadastral?.EMAILS?.REGISTRO_LOCALIZADO === "0" ? (
                          <Card className={`bg-orange-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoAlertCircleOutline fontSize={20} />
                              </div>
                              <div>Sem informação</div>
                            </div>
                          </Card>
                        ) : (
                          <ul className="flex mt-3 gap-x-5 gap-y-2 flex-wrap">
                            {dataCadastral?.EMAILS?.INFOEMAILS?.map(
                              (email, index) => (
                                <li
                                  key={index}
                                  className="flex items-center gap-1 text-sm"
                                >
                                  <MdOutlineEmail />
                                  <div>{email.ENDERECO}</div>
                                </li>
                              )
                            )}
                          </ul>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div className="-mt-4">
                    <TitleStripe>Endereços</TitleStripe>
                    {dataCadastral?.SOMENTE_ENDERECO?.STATUS_RETORNO?.CODIGO ===
                    "1" ? (
                      <>
                        {dataCadastral?.SOMENTE_ENDERECO
                          ?.REGISTRO_LOCALIZADO === "0" ? (
                          <Card className={`bg-orange-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoAlertCircleOutline fontSize={20} />
                              </div>
                              <div>Sem informação</div>
                            </div>
                          </Card>
                        ) : (
                          <Table striped className="text-sm mt-3">
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Endereço
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Bairro
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Cidade
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                UF
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                CEP
                              </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                              {dataCadastral?.SOMENTE_ENDERECO?.DADOS?.map(
                                (endereco, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {endereco.ENDERECO}, {endereco.NUMERO}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {endereco.BAIRRO}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {endereco.CIDADE}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {endereco.UF}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {endereco.CEP}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Números de Celular</TitleStripe>
                    {dataCadastral?.TELEFONE_CELULAR?.STATUS_RETORNO?.CODIGO ===
                    "1" ? (
                      <>
                        {dataCadastral?.TELEFONE_CELULAR
                          ?.REGISTRO_LOCALIZADO === "0" ? (
                          <Card className={`bg-orange-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoAlertCircleOutline fontSize={20} />
                              </div>
                              <div>Sem informação</div>
                            </div>
                          </Card>
                        ) : (
                          <Table striped className="text-sm mt-3">
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-gray-300">
                                DDD
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Telefone
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Nome
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Tipo de pessoa
                              </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                              {dataCadastral?.TELEFONE_CELULAR?.TELEFONES?.map(
                                (celular, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {celular.DDD}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {celular.NUM_TELEFONE}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {celular.NOME}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {celular.TIPO_PESSOA}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Números de telefone fixo</TitleStripe>
                    {dataCadastral?.TELEFONE_FIXO?.STATUS_RETORNO?.CODIGO ===
                    "1" ? (
                      <>
                        {dataCadastral?.TELEFONE_FIXO?.REGISTRO_LOCALIZADO ===
                        "0" ? (
                          <Card className={`bg-orange-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoAlertCircleOutline fontSize={20} />
                              </div>
                              <div>Sem informação</div>
                            </div>
                          </Card>
                        ) : (
                          <Table striped className="text-sm mt-3">
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-gray-300">
                                DDD
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Telefone
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Nome
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Tipo de pessoa
                              </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                              {dataCadastral?.TELEFONE_FIXO?.TELEFONES?.map(
                                (telefone, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {telefone.DDD}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {telefone.NUM_TELEFONE}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {telefone.NOME}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {telefone.TIPO_PESSOA}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Participação em empresas</TitleStripe>
                    {dataCadastral?.PARTICIPACAO_EM_EMPRESAS?.STATUS_RETORNO
                      ?.CODIGO === "1" ? (
                      <>
                        {dataCadastral?.PARTICIPACAO_EM_EMPRESAS
                          ?.QUANTIDADE_OCORRENCIAS === "0" ? (
                          <Card className={`bg-orange-200 mt-4`}>
                            <div className="flex gap-2 items-center font-semibold">
                              <div>
                                <IoAlertCircleOutline fontSize={20} />
                              </div>
                              <div>Nenhuma participação em empresas</div>
                            </div>
                          </Card>
                        ) : (
                          <Table striped className="text-sm mt-3">
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Razão Social
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                CNPJ
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Participação
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Cargo
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Data de entrada
                              </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                              {dataCadastral?.PARTICIPACAO_EM_EMPRESAS?.OCORRENCIAS?.map(
                                (empresa, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {empresa.RAZAO_SOCIAL || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {empresa.CNPJ || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {empresa.PARTICIPACAO || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {empresa.PARTICIPANTE_CARGO || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {empresa.PARTICIPANTE_ENTRADA || "-"}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        )}
                      </>
                    ) : (
                      <Card className={`bg-orange-200 mt-4`}>
                        <div className="flex gap-2 items-center font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <Loader
                words={[
                  "Resumo do veículo",
                  "Análise de kilometragem",
                  "Renajud",
                  "Renafin",
                  "informações do veículo",
                  "ficha técnica",
                  "histórico de furto e roubo",
                  "dados de Leilão",
                ]}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CompletaVeicularQuery;
