import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const NoOcurrence = ({ message = "Nada consta" }) => {
  return (
    <div className={`mt-4`}>
      <div className="flex gap-2 items-center font-semibold text-purple-600">
        <div>
          <IoMdCheckmarkCircleOutline fontSize={20} />
        </div>
        <div>{message}</div>
      </div>
    </div>
  );
};

export default NoOcurrence;
