import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Spinner,
  Table,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaSearch } from "react-icons/fa";
import { isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCPF } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";
import AvisoCard from "../common/query/AvisoCard";
import ProtestosDirCartorio from "../common/query/ProtestosDirCartorio";
import NoOcurrence from "../common/query/NoOcurrence";
import PendenciasFinanceiras from "../common/query/PendenciasFinanceiras";
import Protestos from "../common/query/Protestos";
import ChequesSemFundos from "../common/query/ChequesSemFundos";
import ChequesSustados from "../common/query/ChequesSustados";
import HistoricoConsultas from "../common/query/HistoricoConsultas";
import AcoesCiveis from "../common/query/AcoesCiveis";
import FooterQuery from "./FooterQuery";
import { EvaluationScore } from "../common/query/EvaluationScore";
import ParticipacaoEmEmpresas from "../common/query/ParticipacaoEmEmpresas";
import FalenciaRecuperacaoJudicial from "../common/query/FalenciaRecuperacaoJudicial";
import QueryInput from "../inputs/QueryInput";

const AcessoPrimeCPFQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [protestosDirCartorio, setProtestosDirCartorio] = useState([]);
  const [isDoneRequest, setIsDoneRequest] = useState(false);
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const [dataSerasa, setDataSerasa] = useState(null);
  const [document, setDocument] = useState("");
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    isDoneRequest
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage.consultaCredito);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
      setDataSerasa(dataFromLocalStorage.serasa);
      setProtestosDirCartorio(dataFromLocalStorage.protestosDirCartorio);
      setIsDoneRequest(true);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_ACESSO_PRIME_CPF}/${info.replace(/\D/g, "")}`
    );
  };

  const fetchInfoProtestos = async (raw) => {
    return await axios(
      `${QUERIES.GET_PROTESTO_NACIONAL}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCPF(unmaskedValue)) {
      setError("CPF inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setDataSerasa(null);
        setIsLoading(true);
        setProtestosDirCartorio([]);
        setIsDoneRequest(false);
        setError("");
        setErrorQuery("");

        // Chamada principal: fetchInfo
        const { data: dados } = await fetchInfo();
        setData(dados.data.consultaCredito);
        setDataSerasa(dados.data.serasa);
        setProtestosDirCartorio(dados.data.protestosDirCartorio);
        setIsLoading(false);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
        setIsLoading(false);
      } finally {
        setIsDoneRequest(true);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Score",
          "Negativações",
          "Pendências",
          "Protestos",
          "Ações cíveis",
          "Decisão de crédito",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="card-pdf p-5 relative overflow-hidden">
                  <HeaderQuery title={query?.name} />
                  <TitleStripe>Dados Cadastrais</TitleStripe>
                  <div className="flex gap-16">
                    <div className="text-sm flex-1">
                      <div className="mb-3">
                        <span className="font-semibold">Nome completo: </span>
                        {data?.dadosCadastrais?.nome}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Situação: </span>
                        <span
                          className={
                            data?.dadosCadastrais?.situacao?.toUpperCase() ===
                            "REGULAR"
                              ? "bg-primary text-white rounded-md px-2"
                              : "bg-red-600 text-white rounded-md px-2"
                          }
                        >
                          {data?.dadosCadastrais?.situacao || "Indisponível"}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Título de eleitor:{" "}
                        </span>
                        {data?.dadosCadastrais?.tituloEleitor}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Grau de instrução:{" "}
                        </span>
                        {data?.dadosCadastrais?.grauInstrucao || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Data de nascimento:{" "}
                        </span>
                        {data?.dadosCadastrais?.dataNascimento}
                      </div>
                      <div className="mb-0">
                        <span className="font-semibold">
                          Número de dependentes:{" "}
                        </span>
                        {data?.dadosCadastrais?.numeroDependentes}
                      </div>
                    </div>
                    <div className="text-sm flex-1">
                      <div className="mb-3">
                        <span className="font-semibold">CPF: </span>
                        {document}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Origem CPF: </span>
                        {data?.dadosCadastrais?.regiaoCPF || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Data de atualização:{" "}
                        </span>
                        {data?.dadosCadastrais?.dataAtualizacao ||
                          "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Sexo: </span>
                        {data?.dadosCadastrais?.sexo || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nome da mãe: </span>
                        {data?.dadosCadastrais?.nomeMae}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Estado Civil: </span>
                        {data?.dadosCadastrais?.estadoCivil || "Indisponível"}
                      </div>
                      <div className="mb-0">
                        <span className="font-semibold">Nacionalidade: </span>
                        {data?.dadosCadastrais?.cidadeNascimento ||
                          "Não informado"}
                      </div>
                    </div>
                  </div>
                  <TitleStripe>Quadro de avisos</TitleStripe>
                  <div className="container-alerts four-columns">
                    <AvisoCard
                      title="Negativações"
                      hasRestrictions={
                        +data?.resumoConsulta?.pendenciasFinanceiras
                          ?.quantidadeTotal > 0
                      }
                      messageError={`${data?.resumoConsulta?.pendenciasFinanceiras?.quantidadeTotal} ocorrências`}
                    />
                    {dataSerasa && (
                      <AvisoCard
                        title="Pendências"
                        hasRestrictions={
                          +dataSerasa?.consultaCredito?.resumoConsulta
                            ?.pendenciasFinanceiras?.quantidadeTotal > 0
                        }
                        messageError={`${+dataSerasa?.consultaCredito
                          ?.resumoConsulta?.pendenciasFinanceiras
                          ?.quantidadeTotal} ocorrências`}
                      />
                    )}

                    <AvisoCard
                      title="Ações Cíveis"
                      hasRestrictions={
                        +data?.resumoConsulta?.acoesCiveis?.quantidadeTotal > 0
                      }
                      messageError={`${data?.resumoConsulta?.acoesCiveis?.quantidadeTotal} ocorrências`}
                    />

                    <AvisoCard
                      title="Falência/Rec. Judicial"
                      hasRestrictions={+data?.falenciasRecJudiciais?.length > 0}
                      messageError={`${+data?.falenciasRecJudiciais
                        ?.length} ocorrências`}
                    />

                    <AvisoCard
                      title="Cheques sem Fundos"
                      hasRestrictions={
                        +data?.resumoConsulta?.chequesSemFundo
                          ?.quantidadeTotal > 0
                      }
                      messageError={`${data?.resumoConsulta?.chequesSemFundo?.quantidadeTotal}
                      ocorrências`}
                    />

                    <AvisoCard
                      title="Cheques Sustados"
                      hasRestrictions={
                        +data?.resumoConsulta?.chequesSustados
                          ?.quantidadeTotal > 0
                      }
                      messageError={`${+data?.resumoConsulta?.chequesSustados
                        ?.quantidadeTotal}
                      ocorrências`}
                    />

                    <AvisoCard
                      title="Protestos"
                      hasRestrictions={
                        +data?.resumoConsulta?.protestos?.quantidadeTotal > 0
                      }
                      messageError={`${data?.resumoConsulta?.protestos?.quantidadeTotal} ocorrências`}
                    />

                    <AvisoCard
                      title="Protestos Dir. Cartório"
                      hasRestrictions={protestosDirCartorio?.length > 0}
                      messageError={`${protestosDirCartorio?.length} ocorrências`}
                    />
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Participação Societária
                    </TitleStripe>
                    {data?.participacaoEmEmpresas?.length > 0 ? (
                      <ParticipacaoEmEmpresas
                        empresas={data?.participacaoEmEmpresas}
                      />
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-3">
                      Avaliação preliminar de crédito
                    </TitleStripe>
                    <div className="flex flex-col gap-4">
                      <EvaluationScore
                        score={+data?.score?.score}
                        probability={+data?.score?.probabilidade}
                        decisao={data?.score?.decisao}
                        data={data}
                      />
                    </div>
                  </div>

                  <div>
                    <TitleStripe className="mb-5">Negativações</TitleStripe>

                    {data?.pendenciasFinanceiras?.length > 0 ? (
                      <>
                        <PendenciasFinanceiras
                          quantidadeTotal={
                            data?.resumoConsulta?.pendenciasFinanceiras
                              ?.quantidadeTotal
                          }
                          valorTotal={data?.resumoConsulta?.pendenciasFinanceiras?.valorTotal
                            ?.replace(".", "")
                            ?.replace(",", ".")}
                          pendenciasFinanceiras={data?.pendenciasFinanceiras}
                        />
                      </>
                    ) : (
                      <NoOcurrence />
                    )}

                    {dataSerasa && (
                      <>
                        <TitleStripe className="mb-5">Pendências</TitleStripe>

                        {dataSerasa?.consultaCredito?.pendenciasFinanceiras
                          ?.length > 0 ? (
                          <>
                            <PendenciasFinanceiras
                              quantidadeTotal={
                                dataSerasa?.consultaCredito?.resumoConsulta
                                  ?.pendenciasFinanceiras?.quantidadeTotal
                              }
                              pendenciasFinanceiras={
                                dataSerasa?.consultaCredito
                                  ?.pendenciasFinanceiras
                              }
                            />
                          </>
                        ) : (
                          <NoOcurrence />
                        )}
                      </>
                    )}

                    <TitleStripe className="mb-5">
                      Títulos protestados
                    </TitleStripe>

                    {data?.protestos?.length > 0 ? (
                      <Protestos
                        quantidadeTotal={
                          data?.resumoConsulta?.protestos?.quantidadeTotal
                        }
                        valorTotal={data?.resumoConsulta?.protestos?.valorTotal}
                        protestos={data?.protestos}
                      />
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Protestos direto do Cartório
                    </TitleStripe>
                    {protestosDirCartorio?.length > 0 ? (
                      <Protestos
                        quantidadeTotal={protestosDirCartorio?.length}
                        protestos={protestosDirCartorio}
                      />
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">Ações cíveis</TitleStripe>
                    {data?.acoesCiveis?.length > 0 ? (
                      <AcoesCiveis
                        quantidadeTotal={
                          data?.resumoConsulta?.acoesCiveis?.quantidadeTotal
                        }
                        acoesCiveis={data?.acoesCiveis}
                      />
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Falência/Recuperações judiciais
                    </TitleStripe>
                    {data?.falenciasRecJudiciais?.length > 0 ? (
                      <FalenciaRecuperacaoJudicial
                        falenciaRecuperacaoJudicial={
                          data?.falenciasRecJudiciais
                        }
                      />
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Cheques sem fundos
                    </TitleStripe>

                    {data?.chequesSemFundo?.length > 0 ? (
                      <>
                        <ChequesSemFundos
                          quantidadeTotal={
                            data?.resumoConsulta?.chequesSemFundo
                              ?.quantidadeTotal
                          }
                          chequesSemFundos={data?.chequesSemFundo}
                        />
                      </>
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">Cheques sustados</TitleStripe>

                    {data?.chequesSustados?.length > 0 ? (
                      <>
                        <ChequesSustados
                          quantidadeTotal={
                            data?.resumoConsulta?.chequesSustados
                              ?.quantidadeTotal
                          }
                          chequesSemFundos={data?.chequesSustados}
                        />
                      </>
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Histórico de Consultas
                    </TitleStripe>

                    {data?.historicoConsultas?.length > 0 ? (
                      <>
                        <HistoricoConsultas
                          historicoConsultas={data?.historicoConsultas?.slice(
                            0,
                            5
                          )}
                        />
                      </>
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>
                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <QueryLoading />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AcessoPrimeCPFQuery;
