import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Spinner,
  Table,
  TextInput,
  Timeline,
} from "flowbite-react";
import React, { useRef, useState } from "react";
import { FaHandPointDown, FaRegCheckCircle } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import {
  formatToReal,
  getImageURLScore,
  isValidCNPJ,
  maskCEP,
} from "../../utils/utils";
import ReactApexChart from "react-apexcharts";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";
import NoOcurrence from "../common/query/NoOcurrence";
import QuadroSocietario from "../common/query/QuadroSocietario";
import PendenciasFinanceiras from "../common/query/PendenciasFinanceiras";
import Protestos from "../common/query/Protestos";
import AcoesCiveis from "../common/query/AcoesCiveis";
import ChequesSemFundos from "../common/query/ChequesSemFundos";
import ChequesSustados from "../common/query/ChequesSustados";
import HistoricoConsultas from "../common/query/HistoricoConsultas";
import AvisoCard from "../common/query/AvisoCard";
import FooterQuery from "./FooterQuery";
import ProtestosDefineLimite from "../common/query/ProtestosDefineLimite";

const DefineLimiteQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [data, setData] = useState(null);
  const [protestosDirCartorio, setProtestosDirCartorio] = useState([]);
  const [isDoneRequest, setIsDoneRequest] = useState(false);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    isDoneRequest
  );

  const fetchDefineLimite = async (raw) => {
    return await axios(
      `${QUERIES.GET_DEFINE_LIMITE}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCNPJ(unmaskedValue)) {
      setError("CNPJ inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setProtestosDirCartorio([]);
        setIsLoading(true);
        setIsDoneRequest(false);
        setError("");
        setErrorData("");

        // Fetch principal
        const { data: dados } = await fetchDefineLimite();
        setData(dados.data["defineLimite"]);
        setProtestosDirCartorio(dados.data.protestosDirCartorio);
        setIsLoading(false);
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
        setIsLoading(false);
      } finally {
        setIsDoneRequest(true);
      }
    }
  };

  const calcMarkNegotiation = () => {
    switch (data?.decisao?.descricao?.toLowerCase()) {
      case "negociação não recomendada":
        return "14%";
      case "negociação em análise":
        return "48%";
      case "negociação recomendada":
        return "81%";
      default:
        return false;
    }
  };

  return (
    <div>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Score",
          "Faturamento presumido",
          "Falência/Recuperação",
          "Ações cíveis",
          "Negativações",
          "Protestos",
          "Cheques sem fundos",
          "Cheques sustados",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CNPJ"
            />
            <div>
              {!errorData && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorData ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      ) : (
        <div
          ref={componentRef}
          className={`overflow-x-scroll ${
            data || isLoading ? "block" : "hidden"
          }`}
        >
          {!isLoading ? (
            <>
              <Card className="relative overflow-hidden p-5 card-pdf">
                <HeaderQuery title={query?.name} />
                <TitleStripe>Dados Cadastrais</TitleStripe>
                <div className="flex gap-20">
                  <div className="text-sm">
                    <div className="mb-3">
                      <span className="font-semibold">Razão Social: </span>
                      {data?.identificacao_completo?.razao_social}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Nome Fantasia: </span>
                      {data?.identificacao_completo?.nome_fantasia || "-"}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Natureza Jurídica: </span>
                      {data?.identificacao_completo?.natureza_juridica || "-"}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">CNPJ/MF: </span>
                      {document || "-"}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">I.E.: </span>
                      {data?.identificacao_completo?.inscricao_estadual || "-"}
                    </div>

                    <div className="mb-3">
                      <span className="font-semibold">CNAE Principal: </span>
                      {data?.identificacao_completo?.ramo_atividade_primario
                        ?.atividade || "-"}
                    </div>

                    <div className="mb-3">
                      <span className="font-semibold">Fundação: </span>
                      {data?.identificacao_completo?.data_fundacao || "-"}
                    </div>
                  </div>
                  <div className="text-sm">
                    <div className="mb-3 flex gap-2 items-center">
                      <span className="font-semibold">Situação: </span>
                      <span
                        className={
                          data?.identificacao_completo?.situacao_cnpj?.toLowerCase() ===
                          "ativo"
                            ? "text-black"
                            : "text-red-500"
                        }
                      >
                        {data?.identificacao_completo?.situacao_cnpj || "-"}
                      </span>
                    </div>

                    <div className="mb-3">
                      <span className="font-semibold">Endereço: </span>
                      {data?.localizacao_completo?.matriz?.endereco_matriz ||
                        "-"}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Bairro: </span>
                      {data?.localizacao_completo?.matriz?.bairro_matriz || "-"}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Cidade/UF: </span>
                      {data?.localizacao_completo?.matriz?.cidade_matriz || "-"}
                      /{data?.localizacao_completo?.matriz?.uf_matriz || "-"}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">CEP: </span>
                      {maskCEP(
                        data?.localizacao_completo?.matriz?.cep_matriz
                      ) || "-"}
                    </div>
                    <div className="mb-3 mt-12">
                      <span className="font-semibold">Faturamento Anual: </span>
                      <span className="text-blue-500 font-semibold">
                        {data?.faturamento_presumido?.faturamento_anual || "-"}
                      </span>
                    </div>
                    <div>{data?.faturamento_presumido?.mensagem}</div>
                  </div>
                </div>
                <TitleStripe>Quadro de avisos</TitleStripe>
                <div className="container-alerts four-columns">
                  {/* <AvisoCard
                    title="Quadro Societário"
                    hasRestrictions={!data?.cheques_sem_fundo?.mensagem}
                    messageError={`${data?.cheques_sem_fundo?.total_cheques_sem_fundos} 
                        ocorrências`}
                  />

                  <AvisoCard
                    title="Empresas Ligadas"
                    hasRestrictions={!data?.cheques_sem_fundo?.mensagem}
                    messageError={`${data?.cheques_sem_fundo?.total_cheques_sem_fundos} 
                        ocorrências`}
                  /> */}

                  <AvisoCard
                    title="Cheques sem Fundos"
                    hasRestrictions={!data?.cheques_sem_fundo?.mensagem}
                    messageError={`${data?.cheques_sem_fundo?.total_cheques_sem_fundos} 
                        ocorrências`}
                  />

                  <AvisoCard
                    title="Cheques Sustados"
                    hasRestrictions={!data?.cheques_sustados?.mensagem}
                    messageError={`${data?.cheques_sustados?.total} ocorrências`}
                  />

                  <AvisoCard
                    title="Protestos"
                    hasRestrictions={!data?.protestos?.mensagem}
                    messageError={`${data?.protestos?.total_protestos} ocorrências`}
                  />

                  <AvisoCard
                    title="Protestos Dir. Cartório"
                    hasRestrictions={protestosDirCartorio?.length > 0}
                    messageError={`${protestosDirCartorio?.length} ocorrências`}
                  />

                  <AvisoCard
                    title="Negativações"
                    hasRestrictions={!data?.pendencias_restricoes?.mensagem}
                    messageError={`${data?.pendencias_restricoes?.total_pendencias}
                    ocorrências`}
                  />

                  <AvisoCard
                    title="Ações Cíveis"
                    hasRestrictions={!data?.acoes_civeis?.mensagem}
                    messageError={`${data?.acoes_civeis?.total_acoes} ocorrências`}
                  />
                </div>
                <TitleStripe>Negociação</TitleStripe>
                {calcMarkNegotiation() ? (
                  <div className="relative mt-10 mb-2">
                    <div className="flex overflow-hidden rounded-lg w-full shadow-md relative">
                      <div
                        className="bg-red-500 p-3 flex-1 text-center text-sm font-semibold text-red-900 outline outline-green-200"
                        style={{
                          background:
                            "linear-gradient(90deg, rgba(255,26,26,1) 0%, rgba(255,238,64,1) 100%)",
                        }}
                      >
                        Não recomendada
                      </div>
                      <div
                        className="bg-yellow-300 p-3 flex-1 text-center text-sm font-semibold text-yellow-900"
                        style={{
                          background:
                            "linear-gradient(90deg, rgba(255,236,64,1) 0%, rgba(255,236,64,1) 68%, rgba(190,221,95,1) 88%, rgba(48,212,113,1) 100%)",
                        }}
                      >
                        Em análise
                      </div>
                      <div
                        className="bg-green-400 p-3 flex-1 text-center text-sm font-semibold text-green-900"
                        style={{
                          background:
                            "linear-gradient(90deg, rgba(50,213,114,1) 0%, rgba(44,175,95,1) 100%)",
                        }}
                      >
                        Recomendada
                      </div>
                    </div>
                    <div
                      className="absolute -top-10"
                      style={{
                        left: `calc(${calcMarkNegotiation()})`,
                      }}
                    >
                      <FaHandPointDown
                        fontSize={30}
                        className="text-gray-500"
                      />
                    </div>
                  </div>
                ) : (
                  <div>{data?.decisao?.descricao}</div>
                )}

                <div>
                  <TitleStripe>Score de risco</TitleStripe>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="w-3/5 p-4">
                    <img src={getImageURLScore(+data?.score?.score_pj.score)} />
                    <div className="text-center font-semibold text-xl">
                      {+data?.score?.score_pj.score}
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="mb-2">
                      <Alert
                        withBorderAccent
                        color="warning"
                        className="w-fit mb-2"
                      >
                        Probabilidade de Inadimplência ={" "}
                        {data?.score?.score_pj?.probabilidade}%
                      </Alert>{" "}
                      {data?.score?.score_pj?.texto}
                    </div>
                  </div>
                </div>
                <div className="p-2 border border-gray-300 text-sm">
                  <span className="font-semibold">Importante:</span> a decisão
                  de aprovação ou não do crédito é de exclusiva responsabilidade
                  do concedente. as informações prestadas pelo provedor tem como
                  objevo subsidiar essas decisões e, em hipótese alguma devem
                  ser ulizadas como justificativa, pelo concedente do crédito,
                  para a tomada da referida decisão.
                </div>

                <div>
                  <TitleStripe className="mb-5">Limite de crédito</TitleStripe>
                  <div className="mt-2">
                    {data?.limite_credito?.score !== "" ? (
                      <Alert
                        className="text-lg font-semibold shadow"
                        withBorderAccent
                        additionalContent={
                          <div className="text-sm font-normal">
                            {data?.limite_credito?.texto}
                          </div>
                        }
                      >
                        {formatToReal(data?.limite_credito?.score)}
                      </Alert>
                    ) : (
                      <Alert className="mt-2" color="failure" withBorderAccent>
                        {data?.limite_credito?.texto}
                      </Alert>
                    )}
                  </div>
                </div>

                <div>
                  <TitleStripe className="mb-5">Quadro Societário</TitleStripe>
                  {data && !data?.socios?.mensagem ? (
                    <QuadroSocietario
                      socios={
                        Array.isArray(data?.socios?.socio)
                          ? data?.socios?.socio
                          : [...[], data?.socios?.socio]
                      }
                    />
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    {" "}
                    Participações dos Sócios e Administradores em Outras
                    Empresas
                  </TitleStripe>
                  {!data?.participacoes_socios_adm_outras_empresas?.mensagem ? (
                    <>
                      {Array.isArray(
                        data?.participacoes_socios_adm_outras_empresas?.socio
                      ) ? (
                        <>
                          {data?.participacoes_socios_adm_outras_empresas?.socio?.map(
                            (socioP, index) => (
                              <div key={index} className="mt-10">
                                <div className="font-semibold mb-1 text-blue-600 text-center underline">
                                  {socioP.nome_socio}
                                </div>

                                <div className="overflow-x-auto mt-3 mb-5 rounded-lg border border-gray-300">
                                  <Table striped>
                                    <Table.Head className="text-black">
                                      <Table.HeadCell
                                        className="text-center py-2 px-1"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Nº
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="py-2"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Denominação Social
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="text-center py-2 px-1"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Data <br /> Entrada
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="text-center py-2 px-1"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Part. (%)
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="text-center py-2 px-1"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Situação
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="py-2"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Pendências
                                      </Table.HeadCell>
                                    </Table.Head>

                                    <Table.Body className="divide-y">
                                      {Array.isArray(
                                        socioP?.socios_participantes
                                      ) ? (
                                        <>
                                          {socioP?.socios_participantes?.map(
                                            (socio, index) => (
                                              <Table.Row
                                                key={index}
                                                className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black"
                                              >
                                                <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                                  {index + 1}
                                                </Table.Cell>
                                                <Table.Cell className=" py-1 w-96">
                                                  <div>
                                                    {socio.razao_social}
                                                  </div>
                                                  <div
                                                    style={{ fontSize: "10px" }}
                                                  >
                                                    CNPJ: {socio.cnpj}
                                                  </div>
                                                </Table.Cell>
                                                <Table.Cell className="text-center py-1 px-1">
                                                  {socio.entrada}
                                                </Table.Cell>
                                                <Table.Cell className="text-center py-1 px-1 px-1">
                                                  {socio.participacao}
                                                </Table.Cell>
                                                <Table.Cell className="text-center py-1 w-24">
                                                  {socio.situacao_cnpj}
                                                </Table.Cell>
                                                <Table.Cell className="py-1 text-2xs text-black">
                                                  {socio?.indicador_debito?.toUpperCase() ===
                                                  "SIM" ? (
                                                    <>
                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.cheques_sem_fundo
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Cheques Sem Fundos:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.cheques_sem_fundo?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}

                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.protestos
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Protestos:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.protestos?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}

                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.pendencias_restricoes
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Pendências:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}

                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.acoes_judiciais
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Ações cíveis:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}

                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.falencias_recuperacao_judicial
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Rec.
                                                          Judicial/Falências:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <div className="text-blue-500">
                                                      Não há débitos
                                                    </div>
                                                  )}
                                                </Table.Cell>
                                              </Table.Row>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black">
                                            <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                              {1}
                                            </Table.Cell>
                                            <Table.Cell className=" py-1 w-96">
                                              <div>
                                                {
                                                  socioP?.socios_participantes
                                                    ?.razao_social
                                                }
                                              </div>
                                              <div style={{ fontSize: "10px" }}>
                                                CNPJ:{" "}
                                                {
                                                  socioP?.socios_participantes
                                                    ?.cnpj
                                                }
                                              </div>
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 px-1">
                                              {
                                                socioP?.socios_participantes
                                                  ?.entrada
                                              }
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 px-1 px-1">
                                              {
                                                socioP?.socios_participantes
                                                  ?.participacao
                                              }
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 w-24">
                                              {
                                                socioP?.socios_participantes
                                                  ?.situacao_cnpj
                                              }
                                            </Table.Cell>
                                            <Table.Cell className="py-1 text-2xs text-black">
                                              {socioP?.socios_participantes?.indicador_debito?.toUpperCase() ===
                                              "SIM" ? (
                                                <>
                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.cheques_sem_fundo
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Cheques Sem Fundos:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.cheques_sem_fundo?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.protestos?.quantidade !==
                                                    "0" && (
                                                    <div className="uppercase">
                                                      Protestos:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.protestos?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.pendencias_restricoes
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Pendências:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.acoes_judiciais
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Ações cíveis:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.falencias_recuperacao_judicial
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Rec. Judicial/Falências:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div className="text-blue-500">
                                                  Não há débitos
                                                </div>
                                              )}
                                            </Table.Cell>
                                          </Table.Row>
                                        </>
                                      )}
                                    </Table.Body>
                                  </Table>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          <div className="mt-10">
                            <div className="font-semibold mb-1 text-blue-600 text-center underline">
                              {
                                data?.participacoes_socios_adm_outras_empresas
                                  ?.socio.nome_socio
                              }
                            </div>

                            <div className="overflow-x-auto mt-3 mb-5 rounded-lg border border-gray-300">
                              <Table striped>
                                <Table.Head className="text-black">
                                  <Table.HeadCell
                                    className="text-center py-2 px-1"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Nº
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="py-2"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Denominação Social
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="text-center py-2 px-1"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Data <br /> Entrada
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="text-center py-2 px-1"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Part. (%)
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="text-center py-2 px-1"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Situação
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="py-2"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Pendências
                                  </Table.HeadCell>
                                </Table.Head>

                                <Table.Body className="divide-y">
                                  {Array.isArray(
                                    data
                                      ?.participacoes_socios_adm_outras_empresas
                                      ?.socio?.socios_participantes
                                  ) ? (
                                    <>
                                      {data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes?.map(
                                        (socio, index) => (
                                          <Table.Row
                                            key={index}
                                            className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black"
                                          >
                                            <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                              {index + 1}
                                            </Table.Cell>
                                            <Table.Cell className=" py-1 w-96">
                                              <div>{socio.razao_social}</div>
                                              <div style={{ fontSize: "10px" }}>
                                                CNPJ: {socio.cnpj}
                                              </div>
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 px-1">
                                              {socio.entrada}
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 px-1 px-1">
                                              {socio.participacao}
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 w-24">
                                              {socio.situacao_cnpj}
                                            </Table.Cell>
                                            <Table.Cell className="py-1 text-2xs text-black">
                                              {socio?.indicador_debito?.toUpperCase() ===
                                              "SIM" ? (
                                                <>
                                                  {socio?.restricoes_documento
                                                    ?.cheques_sem_fundo
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Cheques Sem Fundos:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.cheques_sem_fundo?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socio?.restricoes_documento
                                                    ?.protestos?.quantidade !==
                                                    "0" && (
                                                    <div className="uppercase">
                                                      Protestos:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.protestos?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socio?.restricoes_documento
                                                    ?.pendencias_restricoes
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Pendências:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socio?.restricoes_documento
                                                    ?.acoes_judiciais
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Ações cíveis:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socio?.restricoes_documento
                                                    ?.falencias_recuperacao_judicial
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Rec. Judicial/Falências:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div className="text-blue-500">
                                                  Não há débitos
                                                </div>
                                              )}
                                            </Table.Cell>
                                          </Table.Row>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black">
                                        <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                          {1}
                                        </Table.Cell>
                                        <Table.Cell className=" py-1 w-96">
                                          <div>
                                            {
                                              data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                ?.razao_social
                                            }
                                          </div>
                                          <div style={{ fontSize: "10px" }}>
                                            CNPJ:{" "}
                                            {
                                              data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                ?.cnpj
                                            }
                                          </div>
                                        </Table.Cell>
                                        <Table.Cell className="text-center py-1 px-1">
                                          {
                                            data
                                              ?.participacoes_socios_adm_outras_empresas
                                              ?.socio?.socios_participantes
                                              ?.entrada
                                          }
                                        </Table.Cell>
                                        <Table.Cell className="text-center py-1 px-1 px-1">
                                          {
                                            data
                                              ?.participacoes_socios_adm_outras_empresas
                                              ?.socio?.socios_participantes
                                              ?.participacao
                                          }
                                        </Table.Cell>
                                        <Table.Cell className="text-center py-1 w-24">
                                          {
                                            data
                                              ?.participacoes_socios_adm_outras_empresas
                                              ?.socio?.socios_participantes
                                              ?.situacao_cnpj
                                          }
                                        </Table.Cell>
                                        <Table.Cell className="py-1 text-2xs text-black">
                                          {data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.indicador_debito?.toUpperCase() ===
                                          "SIM" ? (
                                            <>
                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento
                                                ?.cheques_sem_fundo
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Cheques Sem Fundos:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.cheques_sem_fundo?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}

                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento?.protestos
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Protestos:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.protestos?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}

                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento
                                                ?.pendencias_restricoes
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Pendências:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}

                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento
                                                ?.acoes_judiciais
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Ações cíveis:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}

                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento
                                                ?.falencias_recuperacao_judicial
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Rec. Judicial/Falências:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <div className="text-blue-500">
                                              Não há débitos
                                            </div>
                                          )}
                                        </Table.Cell>
                                      </Table.Row>
                                    </>
                                  )}
                                </Table.Body>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4 mb-5`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Negativações</TitleStripe>
                  {data?.pendencias_restricoes?.ultimas_ocorrencias
                    ?.pendencia_restricao ? (
                    <>
                      <PendenciasFinanceiras
                        quantidadeTotal={
                          data?.pendencias_restricoes?.total_pendencias
                        }
                        dataMaiorPendencia={
                          data?.pendencias_restricoes?.data_maior
                        }
                        valorTotal={data?.pendencias_restricoes?.valor}
                        pendenciasFinanceiras={
                          Array.isArray(
                            data?.pendencias_restricoes.ultimas_ocorrencias
                              ?.pendencia_restricao
                          )
                            ? data?.pendencias_restricoes.ultimas_ocorrencias
                                ?.pendencia_restricao
                            : [
                                ...[],
                                data?.pendencias_restricoes.ultimas_ocorrencias
                                  ?.pendencia_restricao,
                              ]
                        }
                      />
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>
                <div>
                  <TitleStripe className="mb-5">Protestos</TitleStripe>
                  {!!data?.protestos?.total_protestos ? (
                    <ProtestosDefineLimite
                      quantidadeTotal={data?.protestos?.total_protestos}
                      valorTotal={data?.protestos?.valor_total}
                      primeiroValor={data?.protestos?.valor_primeiro}
                      protestos={
                        Array.isArray(
                          data?.protestos?.ultimas_ocorrencias?.protesto
                        )
                          ? data?.protestos?.ultimas_ocorrencias?.protesto
                          : [
                              ...[],
                              data?.protestos?.ultimas_ocorrencias?.protesto,
                            ]
                      }
                    />
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    Protestos direto do Cartório
                  </TitleStripe>
                  {protestosDirCartorio?.length > 0 ? (
                    <Protestos
                      quantidadeTotal={protestosDirCartorio?.length}
                      protestos={protestosDirCartorio}
                    />
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Ações cíveis</TitleStripe>
                  {!!data?.acoes_civeis?.total_acoes ? (
                    <>
                      <AcoesCiveis
                        quantidadeTotal={data?.acoes_civeis?.total_acoes}
                        primeiroValor={data?.acoes_civeis?.valor_primeiro}
                        valorTotal={data?.acoes_civeis?.valor_total}
                        acoesCiveis={
                          Array.isArray(
                            data?.acoes_civeis?.ultimas_ocorrencias?.acao_civel
                          )
                            ? data?.acoes_civeis?.ultimas_ocorrencias
                                ?.acao_civel
                            : [
                                ...[],
                                data?.acoes_civeis?.ultimas_ocorrencias
                                  ?.acao_civel,
                              ]
                        }
                      />
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>
                <div>
                  <TitleStripe className="mb-5">Cheques sem fundo</TitleStripe>
                  {!!data?.cheques_sem_fundo?.total_cheques_sem_fundos ? (
                    <>
                      <ChequesSemFundos
                        quantidadeTotal={
                          data?.cheques_sem_fundo?.total_cheques_sem_fundos
                        }
                        totalNomes={
                          data?.cheques_sem_fundo?.total_nomes_relacionados
                        }
                        chequesSemFundos={
                          Array.isArray(
                            data?.cheques_sem_fundo?.ultimas_ocorrencias
                              ?.cheque_sem_fundo
                          )
                            ? data?.cheques_sem_fundo?.ultimas_ocorrencias
                                ?.cheque_sem_fundo
                            : [
                                ...[],
                                data?.cheques_sem_fundo?.ultimas_ocorrencias
                                  ?.cheque_sem_fundo,
                              ]
                        }
                      />
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>
                <div>
                  <TitleStripe className="mb-5">Cheques sustados</TitleStripe>
                  {data?.cheques_sustados?.ultimas_ocorrencias?.cheques_sustado
                    ?.length > 0 ? (
                    <>
                      <ChequesSustados
                        periodoInicial={data?.cheques_sustados?.periodo_inicial}
                        periodoFinal={data?.cheques_sustados?.periodo_final}
                        quantidadeTotal={data?.cheques_sustados?.total}
                        chequesSustados={
                          Array.isArray(
                            data?.cheques_sustados?.ultimas_ocorrencias
                              ?.cheques_sustado
                          )
                            ? data?.cheques_sustados?.ultimas_ocorrencias
                                ?.cheques_sustado
                            : [
                                ...[],
                                data?.cheques_sustados?.ultimas_ocorrencias
                                  ?.cheques_sustado,
                              ]
                        }
                      />
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    Histórico de consultas
                  </TitleStripe>

                  {data?.consultas?.ultimas_consultas?.consulta?.length > 0 ? (
                    <>
                      <HistoricoConsultas
                        quantidadeTotal={data?.consultas?.quantidade_total}
                        periodoInicial={data?.consultas?.periodo_inicial}
                        periodoFinal={data?.consultas?.periodo_final}
                        historicoConsultas={
                          Array.isArray(
                            data?.consultas?.ultimas_consultas?.consulta
                          )
                            ? data?.consultas?.ultimas_consultas?.consulta
                            : [
                                ...[],
                                data?.consultas?.ultimas_consultas?.consulta,
                              ]
                        }
                      />
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <FooterQuery hasHeader={true} />
              </Card>
            </>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </div>
  );
};

export default DefineLimiteQuery;
