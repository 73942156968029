import { Accordion, Alert, Button, Card } from "flowbite-react";
import Input from "../inputs/Input";
import { useForm } from "react-hook-form";
import PhoneInput from "../inputs/PhoneInput";
import CPFInput from "../inputs/CPFInput";
import { useEffect, useState } from "react";
import SelectInput from "../inputs/SelectInput";
import { useQuery } from "react-query";
import { ADMIN, CATEGORIES, USERS } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import useCEP from "../../hooks/useCEP";
import { formatToReal, isValidCNPJ, isValidCPF } from "../../utils/utils";
import { FaCheckCircle } from "react-icons/fa";
import CNPJInput from "../inputs/CNPJInput";

const AdminCustomerForm = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorQuery, setErrorQuery] = useState(false);

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm();

  const {
    cep,
    setCep,
    address,
    loading: loadingCEP,
    error: errorCEP,
  } = useCEP();

  const onBlurSearchCEP = (e) => {
    setCep(getValues("company.cep"));
  };

  const onBlurInfo = async (e, type) => {
    try {
      await axios.get(
        `${ADMIN.GET_COMPANY_BY_INFO}/${e.target.value}?type=${type}`
      );
      setError(`${e.target.name}`, {
        type: "manual",
        message: "Já possui este campo cadastrado!",
      });
    } catch (error) {
      clearErrors(e.target.name);
    }
  };

  const onBlurEmailInfo = async (e) => {
    try {
      await axios.get(`${USERS.GET_USER_BY_EMAIL}/${e.target.value}`);
      clearErrors(e.target.name);
    } catch (error) {
      setError(`${e.target.name}`, {
        type: "manual",
        message: "Já possui este campo cadastrado!",
      });
    }
  };

  useEffect(() => {
    setValue("company.address", address?.logradouro);
    setValue("company.addressNeighborhood", address?.bairro);
    setValue("company.addressCity", address?.localidade);
  }, [address]);

  useEffect(() => {
    setValue("company.typeCustomer", "CPF");
    setValue("company.typePayment", "PRE");
  }, []);

  const {
    isLoading: isLoadingCategories,
    error: errorCategories,
    data: categories,
  } = useQuery(["categories"], async () => {
    const { data } = await axios.get(`${CATEGORIES.GET_ALL}`);

    data.data.data.forEach((category, catIdx) => {
      category.Queries.forEach((query, queryIdx) => {
        setValue(
          `categories[${catIdx}].queries[${queryIdx}].QueryId`,
          query.id
        );
        setValue(
          `categories[${catIdx}].queries[${queryIdx}].price`,
          query.price
        );
      });
    });

    return data.data.data;
  });

  const {
    isLoading: isLoadingTables,
    error: errorTables,
    data: tables,
  } = useQuery(["tables"], async () => {
    const { data } = await axios.get(`${ADMIN.GET_TABLES}`);

    return data.data.data;
  });

  const {
    isLoading: isLoadingCompanyQueries,
    error: errorCompanyQueries,
    data: tableQueries,
    refetch,
  } = useQuery(
    ["tableQueries", watch("company.tableId")],
    async () => {
      if (watch("company.tableId")) {
        const { data: items } = await axios.get(
          `${ADMIN.GET_TABLE_QUERIES}/${watch("company.tableId")}`
        );

        return items.data;
      }
      return null;
    },
    {
      enabled: watch("company.tableId") !== "",
    }
  );

  useEffect(() => {
    categories?.forEach((category, catIdx) => {
      category?.Queries?.forEach((query, index) => {
        setValue(`categories[${catIdx}].queries[${index}].QueryId`, query.id);

        const tableQuery =
          tableQueries?.find((cq) => cq.QueryId === query.id) || null;

        if (tableQuery) {
          setValue(`categories[${catIdx}].queries[${index}].isActive`, true);
          setValue(
            `categories[${catIdx}].queries[${index}].price`,
            tableQuery.price
          );
        }
      });
    });
  }, [tableQueries]);

  useEffect(() => {
    setValue(
      "company.vcm",
      tables?.find((table) => table.id == watch("company.tableId"))?.vcm || ""
    );
  }, [watch("company.tableId")]);

  const handleStep1Submit = (data) => {
    if (Object.keys(errors).length === 0) {
      setActiveStep(2);
    }
  };

  const checkDocument = (e, type) => {
    const unmaskedValue = e.target.value.replace(/\D/g, "");
    let isValid;

    if (unmaskedValue.length === 11) {
      isValid = isValidCPF(unmaskedValue);
    } else if (unmaskedValue.length === 14) {
      isValid = isValidCNPJ(unmaskedValue);
    } else {
      isValid = false;
    }

    if (!isValid) {
      setError(`company.${type}`, {
        type: "manual",
        message: "Documento Inválido",
      });
    } else {
      clearErrors(`company.${type}`);
      onBlurInfo(e, type);
    }
  };

  const handleStep2Submit = (data) => {
    if (Object.keys(errors).length === 0) {
      setActiveStep(3);
    }
  };

  const handleQueriesData = () => {
    handleSubmit((data) => {
      let queries = [];
      data.categories.forEach((categories) => {
        categories.queries.forEach((query) => {
          if (query.isActive)
            queries = [
              ...queries,
              { QueryId: query.QueryId, price: query.price },
            ];
        });
      });
      window.form = { ...window.form, queries: queries };

      setActiveStep(4);
    })();
  };

  const handleSelectAll = () => {
    categories.forEach((category, catIndex) =>
      category.Queries?.forEach((_, index) => {
        setValue(`categories[${catIndex}].queries[${index}].isActive`, true);
      })
    );
  };

  const handleFinishSubmit = async (data) => {
    setIsSubmitting(true);
    setErrorQuery(false);
    try {
      let newArrayQueries = [];
      data.categories.forEach((cat) => {
        cat.queries.forEach((query) => {
          newArrayQueries = [...newArrayQueries, query];
        });
      });

      data.queries = newArrayQueries.filter((query) => query.isActive);
      await axios.post(ADMIN.NEW_CUSTOMER, data);
      goToStep(5);
    } catch (error) {
      setErrorQuery(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const goToStep = (step) => {
    setActiveStep(step);
  };

  return (
    <Card>
      <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
        <li className="flex md:w-full items-center text-primary dark:text-blue-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
          <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
            {activeStep >= 1 ? (
              <svg
                className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                ariaHidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="var(--primary)"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
            ) : (
              <span className="me-2">1</span>
            )}
            <span className="hidden md:block">Cliente</span>
          </span>
        </li>
        <li className="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
          <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
            {activeStep >= 2 ? (
              <svg
                className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                ariaHidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="var(--primary)"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
            ) : (
              <span className="me-2">2</span>
            )}
            <span className="hidden md:block">Usuários</span>
          </span>
        </li>
        <li className="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
          <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
            {activeStep >= 3 ? (
              <svg
                className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                ariaHidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="var(--primary)"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
            ) : (
              <span className="me-2">3</span>
            )}
            <span className="hidden md:block">Consultas</span>
          </span>
        </li>
        <li className="flex items-center">
          {activeStep === 4 ? (
            <svg
              className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
              ariaHidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="var(--primary)"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
          ) : (
            <span className="me-2">4</span>
          )}
          <span className="hidden md:block">Confirmação</span>
        </li>
      </ol>

      {activeStep === 1 && (
        <form onSubmit={handleSubmit(handleStep1Submit)}>
          <h2 className="title-syncx mb-6">Informações do cliente</h2>
          <div className="flex gap-4 flex-col">
            <div className="flex gap-4 flex-col md:flex-row">
              <SelectInput
                register={register}
                label="Tipo de cliente"
                name="company.typeCustomer"
              >
                <option value="CPF">Física</option>
                <option value="CNPJ">Jurídica</option>
              </SelectInput>
              {watch("company.typeCustomer") === "CPF" ? (
                <>
                  <div className="w-full">
                    <Input
                      type="text"
                      register={register}
                      name="company.name"
                      label="Nome"
                      required={true}
                    />
                  </div>
                  <div className="w-full">
                    <CPFInput
                      type="text"
                      register={register}
                      name="company.document"
                      label="CPF"
                      required={true}
                      onBlur={(e) => checkDocument(e, "document")}
                      value={watch("company.document") || ""}
                    />
                    {errors.company?.document && (
                      <small className="text-xs text-red-500">
                        {errors.company.document.message}
                      </small>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full">
                    <Input
                      type="text"
                      register={register}
                      name="company.socialReason"
                      label="Razão Social"
                      className="w-full"
                      required={true}
                    />
                  </div>
                  <div className="w-full">
                    <CNPJInput
                      type="text"
                      register={register}
                      name="company.document"
                      label="CNPJ"
                      required={true}
                      onBlur={(e) => checkDocument(e, "document")}
                      value={watch("company.document") || ""}
                    />
                    {errors.company?.document && (
                      <small className="text-xs text-red-500">
                        {errors.company.document.message}
                      </small>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="flex gap-4 flex-col md:flex-row">
              <div className="w-full">
                <Input
                  type="email"
                  register={register}
                  name="company.email"
                  label="E-mail"
                  className="w-full"
                  required={true}
                  onBlur={(e) => onBlurInfo(e, "email")}
                />
                {errors.company?.email && (
                  <small className="text-xs text-red-500">
                    {errors.company.email.message}
                  </small>
                )}
              </div>
              <div className="w-full">
                <PhoneInput
                  type="text"
                  register={register}
                  name="company.phoneNumber"
                  label="Celular"
                  required={true}
                  onBlur={(e) => onBlurInfo(e, "phoneNumber")}
                  value={watch("company.phoneNumber") || ""}
                />
                {errors.company?.phoneNumber && (
                  <small className="text-xs text-red-500">
                    {errors.company.phoneNumber.message}
                  </small>
                )}
              </div>
              <SelectInput
                register={register}
                label="Tipo de pagamento"
                name="company.typePayment"
                required={true}
              >
                <option value="PRE">Pré-pago</option>
                <option value="POS">Pós-pago</option>
              </SelectInput>
            </div>
            {watch("company.typeCustomer") === "CNPJ" && (
              <>
                <div className="flex gap-4 flex-col md:flex-row">
                  <div className="w-full">
                    <Input
                      type="text"
                      register={register}
                      name="company.responsibleName"
                      label="Nome do responsável"
                      className="w-full"
                      required={true}
                    />
                  </div>
                  <div className="w-full">
                    <CPFInput
                      type="text"
                      register={register}
                      name="company.responsibleCPF"
                      label="CPF do responsável"
                      required={true}
                      onBlur={(e) => checkDocument(e, "responsibleCPF")}
                      value={watch("company.responsibleCPF") || ""}
                    />
                    {errors.company?.responsibleCPF && (
                      <small className="text-xs text-red-500">
                        {errors.company.responsibleCPF.message}
                      </small>
                    )}
                  </div>
                  <SelectInput
                    register={register}
                    label="Cargo"
                    name="company.position"
                  >
                    <option value="SOCIO">Sócio/administrador</option>
                    <option value="GERENTE">Gerente</option>
                    <option value="ADM">Administrador</option>
                  </SelectInput>
                </div>
              </>
            )}

            <div className="flex gap-4 flex-col md:flex-row">
              <Input
                type="text"
                register={register}
                name="company.cep"
                label="CEP"
                className="w-full"
                required={true}
                onBlur={onBlurSearchCEP}
              />
              <Input
                type="text"
                register={register}
                name="company.address"
                label="Endereço"
                className="w-full"
                required={true}
              />
              <Input
                type="number"
                register={register}
                name="company.addressNumber"
                label="Número"
                className="w-full"
                required={true}
              />
              <Input
                type="text"
                register={register}
                name="company.addressComplement"
                label="Complemento"
                className="w-full"
              />
            </div>
            <div className="flex gap-4 flex-col md:flex-row">
              <Input
                type="text"
                register={register}
                name="company.addressNeighborhood"
                label="Bairro"
                className="w-full"
                required={true}
              />
              <Input
                type="text"
                register={register}
                name="company.addressCity"
                label="Cidade"
                className="w-full"
                required={true}
              />
            </div>
            <Button className="shadow md:max-w-60 mx-auto px-10" type="submit">
              Próximo
            </Button>
          </div>
        </form>
      )}
      {activeStep === 2 && (
        <form onSubmit={handleSubmit(handleStep2Submit)}>
          <h2 className="title-syncx mb-6">Informações do usuário</h2>
          <div className="flex gap-4 flex-col">
            <div className="flex gap-4 flex-col md:flex-row">
              <div className="w-full">
                <Input
                  type="text"
                  register={register}
                  name="customer.name"
                  label="Nome"
                  required={true}
                />
              </div>
              <div className="w-full">
                <Input
                  type="email"
                  register={register}
                  name="customer.email"
                  label="E-mail"
                  className="w-full"
                  required={true}
                  onBlur={onBlurEmailInfo}
                />
                {errors.customer?.email && (
                  <small className="text-xs text-red-500">
                    {errors.customer.email.message}
                  </small>
                )}
              </div>
            </div>
            <div className="flex gap-4 flex-col md:flex-row">
              <PhoneInput
                type="text"
                register={register}
                name="customer.phoneNumber"
                label="Celular"
                required={true}
              />
              <Input
                type="password"
                register={register}
                name="customer.password"
                label="Senha"
                className="w-full"
                required={true}
              />
            </div>

            <div className="flex flex-col md:flex-row justify-center gap-4 mt-10">
              <Button
                onClick={() => goToStep(1)}
                className="w-full md:mb-4 bg-gray-600 md:max-w-60 shadow"
              >
                Voltar
              </Button>

              <Button
                className="w-full md:mb-4 shadow md:max-w-60 px-10"
                type="submit"
              >
                Próximo
              </Button>
            </div>
          </div>
        </form>
      )}
      {activeStep === 3 && (
        <>
          <form className="mb-6">
            {categories?.length > 0 ? (
              <>
                <h2 className="title-syncx mb-6">Escolha as consulta</h2>
                <div className="mb-4 w-80">
                  <SelectInput
                    register={register}
                    label="Modelo de tabela pré-definida"
                    name="company.tableId"
                    required={false}
                  >
                    <option value="" disabled selected>
                      Nenhum selecionado
                    </option>
                    {tables?.map((table, index) => (
                      <option key={index} value={table.id}>
                        {table.name} -{" "}
                        {table.vcm ? formatToReal(table.vcm) : "Sem valor"}
                      </option>
                    ))}
                  </SelectInput>
                </div>
                <div className="mb-2 flex justify-end">
                  <Button size={"xs"} onClick={() => handleSelectAll()}>
                    Selecionar todos
                  </Button>
                </div>
                <Accordion>
                  {categories?.map((category, idxCat) => (
                    <Accordion.Panel key={idxCat}>
                      <Accordion.Title>{category.name}</Accordion.Title>
                      <Accordion.Content>
                        <div className="flex flex-col gap-4">
                          {category?.Queries?.length > 0 ? (
                            <>
                              {category.Queries.map((query, index) => (
                                <div key={index}>
                                  <div className="flex justify-between items-center">
                                    <div className="flex items-center">
                                      <input
                                        id={`checkbox-${query.id}`}
                                        type="checkbox"
                                        name={`categories[${idxCat}].queries[${index}].isActive`}
                                        {...register(
                                          `categories[${idxCat}].queries[${index}].isActive`
                                        )}
                                        className="w-4 h-4 text-cyan-600 bg-gray-100 border-gray-300 rounded focus:ring-cyan-500 dark:focus:ring-cyan-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                      <label
                                        htmlFor={`checkbox-${query.id}`}
                                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >
                                        {query.name}
                                      </label>
                                    </div>
                                    <Input
                                      type="number"
                                      step="0.01"
                                      register={register}
                                      name={`categories[${idxCat}].queries[${index}].price`}
                                      className="w-30"
                                      label="Preço"
                                    />
                                    <Input
                                      type="hidden"
                                      step="0.01"
                                      register={register}
                                      name={`categories[${idxCat}].queries[${index}].QueryId`}
                                      className="hidden"
                                      label="Id da query"
                                    />
                                  </div>
                                  <hr className="mt-4" />
                                </div>
                              ))}
                            </>
                          ) : (
                            <div className="text-sm md:text-md text-center">
                              Não há nenhuma consulta cadastrada nesta categoria
                            </div>
                          )}
                        </div>
                      </Accordion.Content>
                    </Accordion.Panel>
                  ))}
                </Accordion>
                <div className="flex flex-col md:flex-row justify-center gap-4 mt-10">
                  <Button
                    className="w-full md:mb-4 bg-gray-600 md:max-w-60 shadow"
                    onClick={() => goToStep(2)}
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={handleQueriesData}
                    className="w-full md:mb-4 primary md:max-w-60 shadow"
                  >
                    Próximo
                  </Button>
                </div>
              </>
            ) : (
              <div className="text-sm md:text-md text-center">
                Não há nenhuma categoria cadastrada. <br />
                Comece cadastrando as categorias e os permissões primeiro.
              </div>
            )}
          </form>
        </>
      )}
      {activeStep === 4 && (
        <form onSubmit={handleSubmit(handleFinishSubmit)}>
          {" "}
          <h2 className="title-syncx mt-2">Revise as informações do cliente</h2>
          <div className="flex gap-4 flex-col mt-4">
            {watch("company.typeCustomer") === "CPF" ? (
              <div>Nome: {watch("company.name")}</div>
            ) : (
              <>
                <div>Nome fantasia: {watch("company.fantasyName")}</div>
              </>
            )}
            <div>E-mail: {watch("company.email")}</div>
            <div>Celular: {watch("company.phoneNumber")}</div>
            {watch("company.typeCustomer") === "CNPJ" && (
              <>
                <div>
                  Nome do responsável: {watch("company.responsibleName")}
                </div>
                <div>CPF do responsável: {watch("company.responsibleCPF")}</div>
              </>
            )}
            <div>CEP: {watch("company.cep")}</div>
            <div>Endereço: {watch("company.address")}</div>
            <div>Número: {watch("company.addressNumber")}</div>
            {watch("company.addressComplement") && (
              <div>Complemento: {watch("company.addressComplement")}</div>
            )}
            <div>Bairro: {watch("company.addressNeighborhood")}</div>
            <div>Cidade: {watch("company.addressCity")}</div>
            <div>
              Tipo de pagamento:{" "}
              <span className="font-semibold">
                {watch("company.typePayment")}
              </span>
            </div>
            {watch("company.vcm") && (
              <div>
                VCM:{" "}
                <span className="font-semibold">
                  {formatToReal(watch("company.vcm"))}
                </span>
              </div>
            )}
          </div>
          <h2 className="title-syncx mt-10">
            Revise as informações do usuário
          </h2>
          <div className="flex gap-4 flex-col mt-4">
            <div>Nome: {watch("customer.name")}</div>
            <div>E-mail: {watch("customer.email")}</div>
            <div>Celular: {watch("customer.phoneNumber")}</div>
            <div className="flex flex-col md:flex-row justify-center gap-4 mt-10">
              <Button
                onClick={() => goToStep(3)}
                className="w-full md:mb-4 bg-gray-600 md:max-w-60 shadow"
              >
                Voltar
              </Button>
              <Button
                type="submit"
                className="w-full md:mb-4 primary md:max-w-60 shadow"
                isProcessing={isSubmitting}
              >
                Finalizar
              </Button>
            </div>
          </div>
          {errorQuery && (
            <Alert color="failure" className="mt-5">
              Erro ao tentar cadastrar o cliente!
            </Alert>
          )}
        </form>
      )}
      {activeStep === 5 && (
        <div className="flex flex-col h-40 justify-center items-center font-semibold text-lg gap-2">
          <FaCheckCircle fontSize={26} style={{ color: "var(--primary)" }} />
          <div>Cliente cadastrado com sucesso!</div>
        </div>
      )}
    </Card>
  );
};

export default AdminCustomerForm;
